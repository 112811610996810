import React from 'react';
import './index.css';

class Page extends React.Component {

  constructor(props) {
    super();

    this.state = {
      completed: props.completed
    }
  }

  render() {
    return (
      <div className="assessment-progress-bar-wrapper">
        <div className="assessment-progress-bar">
          <div className="bar-phase bar-phase-red"></div>
          <div className="bar-phase bar-phase-yellow"></div>
          <div className="bar-phase bar-phase-green"></div>
          <div className="bar-phase triangle-marker" style={{ width: this.props.completed + '%' }}></div>
        </div>
      </div>
    );
  }
}

export default Page
