import React from 'react';
import './index.css';
import { Row, Col } from 'antd';

class ListAssessment extends React.Component {

  render() {
    return (
        <>
            <div className="content-list-assessment" >
                <Row align="top" style={{minHeight: 50, flexWrap: 'noWrap'}}>
                    <Col align="top" sm={1}>
                        <img alt="Icon" heigth="40" src={this.props.icon} style={{marginTop: 16}}/>
                    </Col>
                    <Col sm={23} style={{ lineHeight: 1.4}}>
                        {this.props.description}
                    </Col>
                </Row>                    
            </div>
        </>
    );
  }
}

export default ListAssessment
