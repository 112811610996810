import React from "react";
import { ConfirmationModal, Toast } from "frst-components";

export default function ComfirmModalDownloadAssessment({
    emailUser,
    handleCloseModal,
    handleCloseToastDownload,
    isToastOpen,
    messageToast,
    open,
    typeToast
}) {
    return (
        <>
            <ConfirmationModal
                onClose={handleCloseModal}
                labelButton="Ok, entendi"
                onConfirm={handleCloseModal}
                open={open}
                title="Download de arquivo"
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '32px'
                    }}
                >
                    <img src="https://cdn-images.frstfalconi.cloud/dowload.png" alt="Download" />
                    <p style={{
                        marginTop: '32px',
                        fontFamily: 'PT Sans',
                        fontSize: '18px',
                        color: '#222',
                        fontWeight: 700
                    }}>
                        Arquivo enviado! Verifique sua caixa de entrada.
                    </p>
                    <p style={{
                        marginTop: '16px',
                        fontFamily: 'PT Sans',
                        fontSize: '16px',
                        color: '#222'
                    }}>
                        Devido a questões de segurança o arquivo foi enviado para o seu e-mail:
                    </p>
                    <p style={{
                        fontFamily: 'PT Sans',
                        fontSize: '16px',
                        color: '#222',
                        marginBottom: '19px'
                    }}>
                        {emailUser}
                    </p>
                </div>
            </ConfirmationModal>
            {isToastOpen && (
                <Toast
                    type={typeToast}
                    message={messageToast}
                    onClose={handleCloseToastDownload}
                />
            )}
        </>
    );
}
