import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, Button, Row, Col, notification } from "antd";
import _lodash from "lodash";
import AssessmentProgressBar from "../progress";
import "./index.css";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
// import renderHTML from 'react-render-html'
import parse from "html-react-parser";

const { TextArea } = Input;
const { REACT_APP_BLOCK_TEXT_PAGE } = process.env;

const QuestionPage = (props) => {
  const [questions, setQuestions] = useState(props.assessment.form?.quiz?.all);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [currentAnswerText, setCurrentAnswerText] = useState(props.assessment.form?.quiz?.all[0].response);
  const [currentWordsCount, setCurrentWordsCount] = useState(0);
  // const [wordsCount, setWordsCount] = useState(props.wordCount);
  const [wordsPercentage, setWordsPercentage] = useState(
    (props.wordCount * 100) / (props.assessment.form?.config.word_min_count * 2)
  );
  const [isValidToSend, setIsValidToSend] = useState(false);
  const blockTextPage = REACT_APP_BLOCK_TEXT_PAGE === "true";
  const [questionResponse, setQuestionResponse] = useState(1);
  const [questionRefresh, setQuestionRefresh] = useState(0);

  let { assessmentType } = useParams();

  const minimumWords = props.assessment.form?.config.word_min_count;
  const wordsNumberSuggestion = assessmentType === "assessment" ? 18 : 38;
  const questionTotal = props.assessment.form?.quiz?.all.length;
  const doneTypingInterval = 3000;
  var typingTimer;
  const [digitando, setDigitando] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setIsValidToSend(_lodash.words(currentAnswerText).length > 0);
  }, [currentAnswerText]);

  useEffect(() => {
    const totalWords = currentAnswerText.split(" ").length;
    setIsValidToSend(totalWords > 0);
  }, [currentAnswerText]);

  useEffect(() => {
    if (questionIndex >= questions.length && props.wordCount < minimumWords) {
      ///
      setQuestionRefresh(questionRefresh + 1);
      setQuestionIndex(0);
      setCurrentAnswerText(questions[0].response);
    } else {
      setCurrentAnswerText(questions[questionIndex].response);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionIndex]);

  useEffect(() => {
    setWordsPercentage((props.wordCount * 100) / (props.assessment.form?.config.word_min_count * 2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.wordCount]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /// ------------------------------------------------------------------------
  /// Renders
  /// ------------------------------------------------------------------------
  const renderButtonBack = () => {
    if (questions.length && questionIndex >= 0 && questionIndex < questions.length) {
      return (
        <Button
          htmlType="button"
          className="btn-big btn-orange mr-3 question-text-button"
          onClick={() => {
            props.onBack();
          }}
          id="buttom-back"
        >
          {parse(t("general.buttomBack"))}
        </Button>
      );
    }
  };

  const renderButtonSkipQuestion = () => {
    if (questions.length && questionIndex >= 0 && questionIndex < questions.length) {
      return (
        <Button
          className="btn-link question-text-button-link"
          type="link"
          onClick={() => {
            skipQuestion();
          }}
          style={{ paddingLeft: 0 }}
          id="button-skip-question"
        >
          {parse(t("general.buttomSkipQuestion"))}
        </Button>
      );
    }
  };

  const renderButtonRestartQuestions = () => {
    if (questionIndex >= questions.length && props.wordCount < minimumWords) {
      return (
        <>
          <Button
            size="large"
            id="button-assessment-text"
            className="btn-big btn-orange btn-write mr-3"
            onClick={() => {
              // goTo(appConfig.appRoutes.userRunReassessmentTextPath)
            }}
          >
            {parse(t("runAssessment.buttomAssessmentText"))}
          </Button>
          <Button
            htmlType="button"
            onClick={() => {
              // restartQuestions()
            }}
            lassName="btn-big btn-orange question-question-button-finish"
            type="primary"
            id="button-assessment-answer-again"
          >
            {parse(t("runAssessment.buttomAssessmentAnswerAgain"))}
          </Button>
        </>
      );
    }
  };

  const renderButtonSendAnswer = () => {
    if (questionIndex >= 0 && questionIndex < questions.length) {
      return (
        <Button
          htmlType="button"
          disabled={!isValidToSend || props.loading || digitando || currentAnswerText.length === 0}
          onClick={() => {
            if (currentAnswerText.length > 0) {
              let newAssessment = props.assessment;
              newAssessment.form.quiz.all[questionIndex].response = currentAnswerText;
              newAssessment.form.quiz.all[questionIndex].complete = true;
              props.onSave(newAssessment);
              setQuestions(newAssessment.form.quiz.all);
              nextQuestion();
            }
          }}
          className="btn-big btn-orange question-text-button-finish"
          id="button-send-for-evaluation"
        >
          {parse(t("text.buttomSubmitForEvaluation"))}
        </Button>
      );
    }
  };

  const renderButtonFinish = () => {
    // if (wordsPercentage >= 50) {
    return (
      <Button
        htmlType="button"
        loading={!isValidToSend || props.loading || digitando}
        className="btn-big btn-orange question-question-button-finish"
        disabled={wordsPercentage <= 50}
        onClick={() => {
          let newAssessment = props.assessment;
          newAssessment.form.quiz.all[questionIndex].response = currentAnswerText;
          newAssessment.form.quiz.all[questionIndex].complete = true;
          newAssessment.close = true;
          props.onFinish(newAssessment);
        }}
        id="button-finish"
      >
        {parse(t("text.buttomFinish"))}
      </Button>
    );
    // }
  };

  const nextQuestion = () => {
    if (questionIndex < questions.length - 1) {
      setQuestionIndex(questionIndex + 1);
      setQuestionResponse(questionResponse + 1);
    } else {
      setQuestionIndex(0);
      setQuestionResponse(1);
    }
  };

  const skipQuestion = () => {
    nextQuestion();
    setCurrentWordsCount(0);
  };

  /// ------------------------------------------------------------------------
  /// Return
  /// ------------------------------------------------------------------------
  return (
    <div>
      <h1 className="title">{parse(t("text.title2"))}</h1>

      <Form layout="vertical">
        {questionIndex >= 0 && questionIndex < questions.length ? (
          <Fragment>
            <Row style={{ marginBottom: 0, paddingBottom: 0, fontSize: 16 }}>
              <Col span={24}>
                <p>{questions[questionIndex].question}</p>
              </Col>
              <div style={{ float: "right", width: "100%", textAlign: "right" }}>
                {questionIndex >= 0 && questionIndex < questions.length ? (
                  <p className="question-counter">
                    {parse(t("text.questionText"))} {questionResponse} {parse(t("text.questionParte"))} {questionTotal}
                  </p>
                ) : null}
              </div>
            </Row>
            <Row>
              <Col span={24} style={{ marginTop: -5 }}>
                <Form.Item>
                  <TextArea
                    rows={4}
                    value={currentAnswerText}
                    onChange={(e) => {
                      setCurrentAnswerText(e.target.value);
                    }}
                    onKeyUp={() => {
                      clearTimeout(typingTimer);
                      typingTimer = setTimeout(() => {
                        let newAssessment = props.assessment;
                        newAssessment.form.quiz.all[questionIndex].response = currentAnswerText;
                        props.onSave(newAssessment);
                        setTimeout(() => {
                          setDigitando(false);
                        }, 500);
                      }, doneTypingInterval);
                    }}
                    onKeyDown={() => {
                      clearTimeout(typingTimer);
                      setDigitando(true);
                    }}
                    onPaste={(e) => {
                      if (blockTextPage) {
                        notification["warning"]({
                          message: parse(t("general.alertCopyPasteTitle")),
                          description: parse(t("general.alertCopyPasteText")),
                        });
                        e.preventDefault();
                        return false;
                      }
                    }}
                  />
                  {currentWordsCount >= 1 && (
                    <p style={{ fontSize: 13, marginTop: 10 }}>
                      <span className="question-counter">
                        {currentWordsCount} {parse(t("text.questionParte"))} {parse(t("text.questionMinimumWords"))}{" "}
                      </span>{" "}
                      {parse(t("text.questionMinimumWords2"))} {wordsNumberSuggestion}{" "}
                      {parse(t("text.questionMinimumWords3"))}{" "}
                    </p>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end" style={{ marginTop: -35 }}>
              <Col className="assessment-progress-bar-content">
                <AssessmentProgressBar completed={wordsPercentage} />
                {minimumWords - props.wordCount > 0 ? (
                  <div style={{ textAlign: "right", fontSize: 13 }}>
                    {parse(t("general.minimumWords1"))}
                    <span style={{ color: "#ff8559", fontWeight: "bold" }}>
                      {digitando ? (
                        <>
                          <div className="loader"></div>
                        </>
                      ) : (
                        `${minimumWords - props.wordCount < 0 ? 0 : minimumWords - props.wordCount} ${parse(
                          t("general.minimumWords2")
                        )} `
                      )}
                    </span>
                    {parse(t("general.minimumWords3"))}
                  </div>
                ) : (
                  digitando && (
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                      <div className="loader"></div>
                    </div>
                  )
                )}
              </Col>
            </Row>
          </Fragment>
        ) : wordsPercentage < 50 ? (
          <>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              <Col span={24}>
                <p>
                  {parse(t("text.warningTextMinimumWords"))}{" "}
                  <span style={{ fontSize: 16 }} className="question-counter">
                    {parse(t("text.warningTextMinimumWords2"))}
                  </span>
                  {parse(t("text.warningTextMinimumWords3"))}
                </p>
              </Col>
            </Row>
          </>
        ) : null}
        <div style={{ marginTop: "10px" }}>
          {renderButtonBack()}
          {renderButtonSkipQuestion()}
          {renderButtonRestartQuestions()}

          {renderButtonSendAnswer()}
        </div>
        <div style={{ marginTop: "40px", display: "flex", justifyContent: "end" }}>{renderButtonFinish()}</div>
      </Form>
    </div>
  );
};

export default QuestionPage;
