const getToken = async (token) => {
    try {
        const userData = JSON.parse(window.localStorage.getItem('userData'))
        return userData?.backend_token
    } catch (error) {
        console.error('Error get Token')
    }
}

const BackendTokenService = {
    getToken,
}

export default BackendTokenService