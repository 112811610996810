import React from "react";

import './HowTo.scss';

import l1 from "../../assets/img/how-to/l1.svg";
import l2 from "../../assets/img/how-to/l2.svg";
import l3 from "../../assets/img/how-to/l3.svg";
import l4 from "../../assets/img/how-to/l4.svg";
import l5 from "../../assets/img/how-to/l5.svg";
import l6 from "../../assets/img/how-to/l6.svg";
import l7 from "../../assets/img/how-to/l7.svg";
import l8 from "../../assets/img/how-to/l8.svg";
import r1 from "../../assets/img/how-to/r1.svg";
import r2 from "../../assets/img/how-to/r2.svg";
import r3 from "../../assets/img/how-to/r3.svg";
import r4 from "../../assets/img/how-to/r4.svg";
import r5 from "../../assets/img/how-to/r5.svg";
import r6 from "../../assets/img/how-to/r6.svg";
import r7 from "../../assets/img/how-to/r7.svg";

/// I18N
import { useTranslation } from "react-i18next";
import "../../translations/i18n";

function HowToNoCapsule({data}) {

  const { t } = useTranslation();

  return (
    <section className="how-to no-capsule">
      <header>
        <div
          style={{display: "flex", justifyContent: "center", alignItems: "center"}}
        >
          <div
            style={{width: 200, position: 'relative'}}
          >
            <h3
            style={{position: 'absolute', color: '#FFED00', textAlign: "center", fontSize: 28, fontWeight: 700, marginRight: 180, letterSpacing: -0.5, transform: 'rotate(-3deg) translateX(-40px)'}}        
          >
            {t("report.comoFunciona1")}
          </h3>
          <h3
            style={{whiteSpace: 'nowrap', color: '#FFED00', marginTop: 20, textAlign: "center", fontSize: 48, fontWeight: 700, letterSpacing: -0.5, transform: 'rotate(-3deg) translateX(-40px)'}}        
          >
            {t("report.comoFunciona2")}
          </h3>                  
          </div>
        </div>
      </header>

      <div className="container" style={{padding: 20}}>
        <div className="info1">
          <h4>{t("report.caractSocioemocionais_title")}</h4>
          <p>{t("report.caractSocioemocionais_desc")}</p>
        </div>

        <div className="info2">
          <h4>{t("report.intArtificial_title")}</h4>
          <p>{t("report.intArtificial_desc")}</p>
        </div>

        <div className="info3">
          <h4>{t("report.competencias_title")}</h4>
          <p className="p1">{t("report.competencias_part1_desc")}</p>
          <p className="p2">{t("report.competencias_part2_desc")}</p>
        </div>

        <div className="info4">
          <h4>{t("report.trilha_title")}</h4>
          <p>{t("report.trilha_desc")}</p>
        </div>

        <div className="info5">
          <h4>{t("report.evolucao_title")}</h4>
          <p>{t("report.evolucao_desc")}</p>
        </div>
      </div>

      <div className="bg">
        <img src={l1} alt="Ilustração" data-scroll data-scroll-speed="-0.5" data-scroll-direction="horizontal" />
        <img src={l2} alt="Ilustração" data-scroll data-scroll-speed="-0.8" data-scroll-direction="horizontal" />
        <img src={l3} alt="Ilustração" data-scroll data-scroll-speed="-0.8" data-scroll-direction="horizontal" />
        <img src={l4} alt="Ilustração" data-scroll data-scroll-speed="-0.4" data-scroll-direction="horizontal" />
        <img src={l5} alt="Ilustração" data-scroll data-scroll-speed="-0.6" data-scroll-direction="horizontal" />
        <img src={l6} alt="Ilustração" data-scroll data-scroll-speed="-0.35" data-scroll-direction="horizontal" />
        <img src={l7} alt="Ilustração" data-scroll data-scroll-speed="-0.7" data-scroll-direction="horizontal" />
        <img src={l8} alt="Ilustração" data-scroll data-scroll-speed="-1.4" data-scroll-direction="horizontal" />
        <img src={r1} alt="Ilustração" data-scroll data-scroll-speed="0.8" data-scroll-direction="horizontal" />
        <img src={r2} alt="Ilustração" data-scroll data-scroll-speed="0.3" data-scroll-direction="horizontal" />
        <img src={r3} alt="Ilustração" data-scroll data-scroll-speed="0.4" data-scroll-direction="horizontal" />
        <img src={r4} alt="Ilustração" data-scroll data-scroll-speed="-0.7" data-scroll-direction="horizontal" />
        <img src={r5} alt="Ilustração" data-scroll data-scroll-speed="0.6" data-scroll-direction="horizontal" />
        <img src={r6} alt="Ilustração" data-scroll data-scroll-speed="0.3" data-scroll-direction="horizontal" />
        <img src={r7} alt="Ilustração" data-scroll data-scroll-speed="1.5" data-scroll-direction="horizontal" />
      </div>
    </section>
  );
}

export default HowToNoCapsule;