import React from "react";

import "./Bar.scss";

function Bar({ value }) {
  return (
    <div className="bar">
      <div className="bar-container">
        <div className="bar-value" style={{ transform: `scaleX(${value / 100})` }} />
        <div className="bar-bg" />
      </div>
    </div>
  );
}

export default Bar;
