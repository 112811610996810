import "./index.css";
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Modal } from "antd";

import bgVideoAssessmentPT from "../../../../assets/images/video-cover.png";
import bgVideoAssessment from "../../../../assets/images/firstStepAssessment.jpg";
import {
  iconCalmo,
  iconMail,
  iconPen,
  iconPositiveNegative,
  iconQuestion,
  iconRelogio,
  iconSend,
  iconLearn,
  BackArrow,
} from "../../../../assets/images/icons";
import arrowRightWhite from "../../../../assets/images/arrow-right-white.png";
import authService from "../../../../services/authService";
import assessmentService from "../../../../services/assessmentServiceNew";
import AssessmentSuccess from "../../../../components/assessmentSuccess";
import CompactLoading from "../../../../components/compactLoading";
import ListAssessment from "../../../../components/listAssessment";
import APM from "../../../../components/apm";
import TextPage from "../../../../components/textPage";
import QuestionPage from "../../../../components/questionPage";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
import i18n from "i18next";
import parse from "html-react-parser";

const RunReassessmentPage = () => {
  const [userData] = useState(authService.getCurrentUser());
  const [modalVideoVisible, setModalVideoVisible] = useState(false);
  const [assessment, setAssessment] = useState(null);
  const [assessmentModel, setAssessmentModel] = useState("");
  const [assessmentStatus, setAssessmentStatus] = useState("");
  const [assessmentTaskStatus, setAssessmentTaskStatus] = useState("");
  const [assessmentPermission, setAssessmentPermission] = useState(false);
  const [AssessmentOpenModel, setAssessmentOpenModel] = useState("");
  const [LoadingModel, setLoadingModel] = useState(false);
  const [loadingAssessment, setLoadingAssessment] = useState(true);
  const [modalResetAssessmentVisible, setModalResetAssessmentVisible] = useState(false);
  const [WordCount, setWordCount] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roleCode, setRoleCode] = useState("");
  const [areaName, setAreaName] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [assessmentCode, setAssessmentCode] = useState("");
  ///
  const typeAssessment = "r";
  const typeAssessmentModelText = "wording";
  const typeAssessmentModelQuestion = "quiz";
  const widthDimensions = window.innerWidth >= 640 ? 640 : window.innerWidth - 15;

  const { t } = useTranslation();

  const showModalVideo = () => {
    setModalVideoVisible(true);
  };

  const closeModalVideo = () => {
    setModalVideoVisible(false);
  };

  const closeModalResetAssessment = () => {
    setModalResetAssessmentVisible(false);
  };

  const resetAssessment = async () => {
    if (userData) {
      try {
        await assessmentService.reset(assessment);
        setModalResetAssessmentVisible(false);
        loadAssessment(null);
      } catch (error) {
        APM.logError(userData.uuid, "saveData", error);
      }
    }
  };

  const loadPermissions = async (funCallback) => {
    if (userData) {
      try {
        setLoadingAssessment(true);

        let assessmentPermissionResult = userData.assessment_date;
        let result = assessmentPermissionResult.filter((item) => {
          return item.type === "reassessment";
        });

        if (result.length === 0) {
          setAssessmentPermission(true);
        }

        await getInfosComplementares(userData.uuid, userData.backend_token);
        await loadAssessment(funCallback);
      } catch (error) {
        APM.logError(userData.uuid, "loadAssessment", error);
      } finally {
        setLoadingAssessment(false);
      }
    }
  };

  const loadAssessment = async (funCallback) => {
    if (userData) {
      setTimeout(async () => {
        let assessmentSessions = await assessmentService.getAssessmentSession(userData.uuid);

        /// Para garantir que a consulta continue caso fique fora do Ar
        if (assessmentSessions.data === undefined) {
          loadAssessment(null);
          return;
        }

        if (assessmentSessions.data && assessmentSessions.data.length > 0) {
          let assessmentResult = assessmentSessions.data.filter((e) => {
            return e.assessment_type === typeAssessment;
          });

          if (assessmentResult.length > 0) {
            setAssessmentModel(assessmentResult[0].session_type);
            setAssessmentStatus(assessmentResult[0].session_status);
            setAssessmentTaskStatus(assessmentResult[0].assessment_status);
            setAssessment(assessmentResult[0]);
            setWordCount(assessmentResult[0].form.word_count);
            setAssessmentCode(assessmentResult[0].assessment_code);
            if (funCallback) {
              funCallback();
            }

            if (assessmentResult[0].session_status === "close" && assessmentResult[0].assessment_status !== "success") {
              setTimeout(() => {
                loadAssessment(null);
              }, 4000);
            }
          } else {
            createNewSession();
          }
        } else {
          createNewSession();
        }
      }, 1000);
    }
  };

  const createNewSession = () => {
    setAssessmentStatus("open");
    setAssessmentModel("");
    setAssessmentTaskStatus("");
    setAssessment(null);
  };

  const saveData = async (newData) => {
    if (userData) {
      try {
        setLoadingModel(true);
        let result = await assessmentService.setAssessment(newData);
        setAssessment(result.data);
        setWordCount(result.data.form.word_count);
      } catch (error) {
        console.error(error);
        APM.logError(userData.uuid, "saveData", error);
      } finally {
        setLoadingModel(false);
      }
    }
  };

  const newSession = async (sessionType) => {
    if (userData) {
      try {
        await assessmentService.setAssessmentSession(
          userData.name,
          userData.email,
          userData.uuid,
          companyName,
          companyCode,
          roleName,
          roleCode,
          areaName,
          areaCode,
          userData.language,
          typeAssessment,
          sessionType
        );

        loadAssessment(() => setAssessmentOpenModel(sessionType));
      } catch (error) {
        APM.logError(userData.uuid, "saveData", error);
      }
    }
  };

  const getInfosComplementares = async () => {
    if (userData) {
      try {
        setLoadingAssessment(true);
        // let result = await assessmentService.getInfosComplementares(user.id, user.token);

        /// Salvando
        setCompanyName(userData?.current_group?.root?.name);
        setCompanyCode(userData?.current_group?.root?.uuid);
        setRoleName(userData?.profile?.profile?.role_name);
        setRoleCode(userData?.profile?.profile?.role_uuid);
        setAreaName(userData?.profile?.profile?.area_name);
        setAreaCode(userData?.profile?.profile?.area_uuid);
      } catch (error) {
        APM.logError(userData.uuid, "saveData", error);
      } finally {
        setLoadingAssessment(false);
      }
    }
  };

  useEffect(() => {
    i18n.changeLanguage(userData.language);
    window.localStorage.setItem("assessmentType", "2");
    loadPermissions(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <div className="main-wrapper">
      <div className="main-container">
        <div className="main-container-inner">
          <div className="spanLink" onClick={() => window.open(`https://hall.frstfalconi.cloud/home`, "_top")}>
            {" "}
            <BackArrow /> {t("back")}{" "}
          </div>
          {loadingAssessment ? (
            <>
              <CompactLoading description={parse(t("runAssessment.loading"))} />
            </>
          ) : (
            <>
              {assessmentPermission ? (
                <>
                  <div className="content-container">
                    <h1 className="title">{parse(t("runAssessment.permissionTitle"))}</h1>
                    <h5 className="global-title-h5" style={{ marginBottom: "50px" }}>
                      {parse(t("runAssessment.permissionText_2"))}
                    </h5>

                    {window.localStorage.getItem("hallAccess") === "false" ? (
                      <a
                        data-cy="btn-resultado-feedback"
                        className="btn-big btn-orange btn-no-icon"
                        href={process.env.REACT_APP_MASTER_URL}
                      >
                        {parse(t("runAssessment.permissionBack"))}
                      </a>
                    ) : null}
                  </div>
                </>
              ) : assessmentStatus === "open" && AssessmentOpenModel === "" ? (
                <>
                  {/* Video Inicial */}
                  <Row data-cy="row-dash-video" className="content-image" justify="center">
                    <Col justify="center" sm={20} md={14}>
                      {userData.language === "en-US" ? (
                        <>
                          <img className="img-how-works" src={bgVideoAssessment} alt="" onClick={showModalVideo} />
                          <img
                            data-cy="row-img-video"
                            className="img-play-button"
                            src={arrowRightWhite}
                            alt=""
                            onClick={showModalVideo}
                          />

                          <div className="text-center">
                            <Modal
                              destroyOnClose={true}
                              visible={modalVideoVisible}
                              footer={null}
                              width={640}
                              zIndex={1061}
                              bodyStyle={{ padding: 0, height: 360 }}
                              onCancel={closeModalVideo}
                            >
                              <div
                                data-cy="div-video"
                                dangerouslySetInnerHTML={{
                                  __html: `<iframe src="https://player.vimeo.com/video/475879762?autoplay=1" width="${widthDimensions}" height="360" frameborder="0"  allow="autoplay; fullscreen" allowfullscreen ></iframe>`,
                                }}
                              />
                            </Modal>
                          </div>
                        </>
                      ) : (
                        <>
                          {!modalVideoVisible && (
                            <>
                              <img
                                className="img-how-works"
                                src={bgVideoAssessmentPT}
                                alt=""
                                onClick={showModalVideo}
                              />
                              <img
                                data-cy="row-img-video"
                                className="img-play-button"
                                src={arrowRightWhite}
                                alt=""
                                onClick={showModalVideo}
                              />
                            </>
                          )}

                          <div className="text-center">
                            <Modal
                              destroyOnClose={true}
                              visible={modalVideoVisible}
                              footer={null}
                              width={640}
                              zIndex={1061}
                              bodyStyle={{ padding: 0, height: 360 }}
                              onCancel={closeModalVideo}
                            >
                              <div
                                data-cy="div-video"
                                dangerouslySetInnerHTML={{
                                  __html: `<iframe src="https://player.vimeo.com/video/836574158?h=0aa0de5905&autoplay=1"  width="${widthDimensions}" height="360" frameborder="0"  allow="autoplay; fullscreen" allowfullscreen ></iframe>`,
                                }}
                              />
                            </Modal>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <div className="content-container">
                    <h1 className="title">{t("runAssessment.title")}</h1>
                    <h5 className="global-title-h5" style={{ marginBottom: "50px" }}>
                      {parse(t("runAssessment.titleDesc_2"))}
                    </h5>
                    <Row>
                      <ListAssessment
                        description={<p className="text-list-assessment">{parse(t("runAssessment.listOption1_2"))}</p>}
                        icon={iconRelogio}
                      />
                      <ListAssessment
                        description={<p className="text-list-assessment">{parse(t("runAssessment.listOption2"))}</p>}
                        icon={iconCalmo}
                      />
                      <ListAssessment
                        description={<p className="text-list-assessment">{parse(t("runAssessment.listOption3_2"))}</p>}
                        icon={iconLearn}
                      />
                      <ListAssessment
                        description={<p className="text-list-assessment">{parse(t("runAssessment.listOption4"))}</p>}
                        icon={iconPen}
                      />
                      <ListAssessment
                        description={<p className="text-list-assessment">{parse(t("runAssessment.listOption5_2"))}</p>}
                        icon={iconPositiveNegative}
                      />
                      <ListAssessment
                        description={<p className="text-list-assessment">{parse(t("runAssessment.listOption6_2"))}</p>}
                        icon={iconSend}
                      />
                      <ListAssessment
                        description={<p className="text-list-assessment">{parse(t("runAssessment.listOption7"))}</p>}
                        icon={iconQuestion}
                      />
                      <ListAssessment
                        description={<p className="text-list-assessment">{parse(t("runAssessment.listOption8_2"))}</p>}
                        icon={iconMail}
                      />
                    </Row>

                    <div className="containerButtons">
                      {assessment && assessment.expire_at && (
                        <p className="assessmentExpireAtText">
                          {parse(t("runAssessment.assessmentExpireAtPart1"))} {assessment.expire_at}{" "}
                          {parse(t("runAssessment.assessmentExpireAtPart2"))}
                        </p>
                      )}
                      {assessmentModel === typeAssessmentModelQuestion ||
                      assessmentModel === typeAssessmentModelText ? (
                        <p className="howMethodText">{parse(t("runAssessment.assessmentContinue"))}</p>
                      ) : (
                        <p className="howMethodText">{parse(t("runAssessment.assessmentChooseOption"))}</p>
                      )}
                      {assessmentModel === "" ? (
                        <>
                          <div className="footer-buttom">
                            <Button
                              size="large"
                              className="btn-big btn-orange btn-write mr-3"
                              onClick={() => {
                                newSession(typeAssessmentModelText);
                              }}
                            >
                              {parse(t("runAssessment.buttomAssessmentText"))}
                            </Button>
                            <Button
                              size="large"
                              className={"btn-big btn-orange btn-answer"}
                              onClick={() => {
                                newSession(typeAssessmentModelQuestion);
                              }}
                            >
                              {parse(t("runAssessment.buttomAssessmentAnwserQuestion"))}
                            </Button>
                          </div>
                        </>
                      ) : null}
                      {assessmentModel === typeAssessmentModelQuestion ||
                      assessmentModel === typeAssessmentModelText ? (
                        <>
                          <div className="footer-buttom">
                            <Button
                              className="btn-big btn-orange btn-no-icon btn-outline"
                              onClick={() => {
                                setModalResetAssessmentVisible(true);
                              }}
                            >
                              {parse(t("runAssessment.buttomResetAssessment"))}
                            </Button>

                            <Button
                              data-cy="btn-dash-pergunta"
                              size="large"
                              className="btn-big btn-orange btn-no-icon"
                              onClick={() => {
                                setAssessmentOpenModel(assessmentModel);
                              }}
                            >
                              {parse(t("runAssessment.buttomContinueAssessment"))}
                            </Button>
                          </div>
                        </>
                      ) : null}
                      <Modal
                        destroyOnClose={true}
                        visible={modalResetAssessmentVisible}
                        title={parse(t("runAssessment.modalResetAssessmentTitle"))}
                        onOk={resetAssessment}
                        onCancel={closeModalResetAssessment}
                        okText={parse(t("runAssessment.modalResetAssessmentOk"))}
                        cancelText={parse(t("runAssessment.modalResetAssessmentCancel"))}
                        width={518}
                        zIndex={1061}
                        bodyStyle={{ padding: 24 }}
                      >
                        <p>{parse(t("runAssessment.modalResetAssessmentText1"))}</p>
                        <p>{parse(t("runAssessment.modalResetAssessmentText2"))}</p>
                        <p>{parse(t("runAssessment.modalResetAssessmentText3"))}</p>
                      </Modal>
                    </div>
                  </div>
                </>
              ) : assessmentStatus === "open" && AssessmentOpenModel === typeAssessmentModelText ? (
                <>
                  <TextPage
                    onBack={() => {
                      setLoadingModel(false);
                      setAssessmentOpenModel("");
                    }}
                    onSave={(title, text) => {
                      let newAssessment = assessment;
                      newAssessment.form.wording.body = text;
                      newAssessment.form.wording.title = title;
                      saveData(newAssessment);
                    }}
                    onFinish={(title, text) => {
                      let newAssessment = assessment;
                      newAssessment.form.wording.body = text;
                      newAssessment.form.wording.title = title;
                      newAssessment.close = "true";
                      saveData(newAssessment);
                      setAssessmentOpenModel("");
                      loadAssessment(null);
                    }}
                    loading={LoadingModel}
                    assessment={assessment}
                    wordCount={WordCount}
                  />
                </>
              ) : assessmentStatus === "open" && AssessmentOpenModel === typeAssessmentModelQuestion ? (
                <>
                  <QuestionPage
                    onBack={() => {
                      setLoadingModel(false);
                      setAssessmentOpenModel("");
                    }}
                    onSave={(newAssessment) => {
                      saveData(newAssessment);
                    }}
                    onFinish={(newAssessment) => {
                      saveData(newAssessment);
                      setLoadingModel(false);
                      setAssessmentOpenModel("");
                      loadAssessment(null);
                    }}
                    loading={LoadingModel}
                    assessment={assessment}
                    wordCount={WordCount}
                  />
                </>
              ) : (
                assessmentStatus === "close" && (
                  <>
                    <AssessmentSuccess
                      assessmentHome={true}
                      assessmentCode={assessmentCode}
                      assessmentTaskStatus={assessmentTaskStatus}
                      assessmentType={2}
                      counter={0}
                      organization={
                        userData?.current_group?.root?.uuid ? 
                        userData?.current_group?.root?.uuid : 
                        userData?.current_group?.root?.uuid
                      }
                    />
                  </>
                )
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RunReassessmentPage;
