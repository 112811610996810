import React from 'react';
import { Row, Col } from 'antd'
import ImgFooterFrst from '../../assets/images/img-footer-frst.png'
import ImgFooterFalconi from '../../assets/images/img-footer-falconi.png'
import './index.css';
import { useTranslation } from 'react-i18next';

const FooterComponent = () => {
    const {t} = useTranslation()
    return (
        <footer>
            <div className="footer-container">
                <Row>
                    <Col lg={12} md={24}  sm={24} xs={24}>
                        <Row>
                            <Col lg={9} md={24} sm={24} xs={24}>
                                <img src={ImgFooterFrst} alt="ImgFooterFrst" className="footer-logo" />
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <ul className="footer-links">
                                    <li><a target="_blank" href="https://frstfalconi.com/termos-de-uso" rel="noreferrer">{t('footer.termsOfUse')}</a></li>
                                    <li><a target="_blank" href="https://frstfalconi.com/politica-de-privacidade" rel="noreferrer">{t('footer.privacyPolicy')}</a></li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <div className="footer-container-right-block">
                            <div>
                                <span className="logo-falconi-title">{t('footer.company')}:</span>
                                <a target="_blank" href="https://falconi.com" rel="noreferrer">
                                    <img src={ImgFooterFalconi} alt="imgfalconi" />
                                </a>
                            </div>
                            <p style={{marginTop: 10}} className="footer-copy">{t('footer.copyright')} &copy;{t('footer.reservedRights')}<br /><small>0.0.8</small></p>
                        </div>
                    </Col>
                </Row>
            </div>
        </footer>
    );
}

export default FooterComponent;