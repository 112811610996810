import React from "react";

import Bar from "../Bar";
import Classification from "../Classification";
import Rating from "../Rating";

import "./CardReport.scss";

function CardReport({ title, subtitle, result, identification, rating, txtResult, txtIdentification, txtRating, index, onClickRating, onClickReaction }) {
  return (
    <div className="card-report">
      <div className="result">
        <h4>{title}</h4>
        <p>{subtitle}</p>
      </div>

      <div className="research">
        <div className="identification">
          <Bar value={result} />
          <p dangerouslySetInnerHTML={{ __html: txtIdentification }} />
          <Classification
            index={index}
            current={identification}
            onClickReaction={(value) => {
              onClickReaction(value);
            }}
          />
        </div>

        <div className="geral">
          <p>{txtResult}</p>
          <p dangerouslySetInnerHTML={{ __html: txtRating }} />
          <Rating
            current={rating}
            onClickRating={(value) => {
              onClickRating(value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CardReport;
