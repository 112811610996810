import pt_BR from 'antd/lib/locale/pt_BR'
import en_US from 'antd/lib/locale/en_US'

const userLanguagePackage = {
  'pt_BR': pt_BR,
  'en_US': en_US,
  'default': pt_BR
}

const getLanguage = () => {
  const userSessionStorage = JSON.parse(window.localStorage.getItem('user'))
  const language = userSessionStorage?.locale || navigator?.language || navigator?.userLanguage
  // const language = navigator.language || navigator.userLanguage
  return language.replace('-', '_')
}

const UserFunction = {
  GetLanguagePackage: () => {
    const userLanguage = getLanguage()
    return userLanguagePackage[userLanguage] || userLanguagePackage['default']
  },

  GetUserLocale: () => {
    return getLanguage()
  }
}

export default UserFunction