import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'

import 'antd/dist/antd.min.css'
import 'antd-css-utilities/utility.min.css'
import '../src/assets/css/global.css'
import '../src/assets/css/menu.css'

import { isAuthenticated } from './services/authService'
import { Layout } from 'antd'
import Header from './components/header'
import Footer from './components/footer'
import APM from './components/apm'
import Routes from './routes'
import UserFunction from './utils/user-functions'

const { Content } = Layout;
const userLanguagePackage = UserFunction.GetLanguagePackage()
const showMenuFooter = window.localStorage.getItem("hallAccess") === "true" || window.location.pathname === "/run-assessment" || window.location.pathname === "/run-reassessment"

APM.getInstance()

let temp = window.location.pathname
temp = temp.split("/")
let isResultAssessment = false

if (temp.length > 2) {
  isResultAssessment = temp[1] === "assessment-result"
}
else {
  isResultAssessment = temp[2] === "assessment-result"
}

ReactDOM.render(
  <>   
    {isResultAssessment ? 
    (
      <Routes />      
    ) 
    :         
      <ConfigProvider locale={userLanguagePackage}>  
        {!isAuthenticated() || window.location.pathname === "/login" ? (
          <Layout>
            <Content>
              <Routes />
            </Content>
            { showMenuFooter ? null : <Footer /> }
          </Layout>
        ) 
        :       
        (
          <Layout>
            { showMenuFooter ? null : <Header /> }
            <Content className={showMenuFooter ? "" : "site-layout"}>
              <Routes />
            </Content>     
            { showMenuFooter ? null : <Footer /> }
          </Layout>
        )}
      </ConfigProvider>
    }
  </>,
  document.getElementById('root')
);
