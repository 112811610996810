import { useEffect, useState } from "react";
import RunAssessmentPage from "../runAssessmentPage/index";
import "./style.css";
import { getDataFromParent } from "iframe-communication";

const HallRunAssessmentPage = () => {
  const [loaded, setLoaded] = useState(false);

  function updateUser(user) {
    let userTempData = user?.userData;
    userTempData.assessment_date = user?.assessment_date;
    userTempData.deimos_token = user?.token;
    userTempData.language = 
      userTempData?.language === 'en-us' ? 'en-US' : (userTempData?.language === 'es-es' ? 'es' : 'pt-BR');

    window.localStorage.setItem("userData", JSON.stringify(userTempData));
    setLoaded(true)
  }

  useEffect(() => {
    setTimeout(() => {
      getDataFromParent("user", updateUser);
    }, 1000);
  }, []);
  
  return <>{
    loaded ? <RunAssessmentPage /> :
    <div className="contentLoading">
      <div>
        {/* <h3></h3> */}
        <div className="loading" ></div>
      </div>
    </div> 
  }</>;
};

export default HallRunAssessmentPage;
