import React, {useState, useEffect} from "react"

import CardReport from "../../components/reportResult/CardReport"
import './Report.scss'

/// Camada Service
import assessmentService from "../../services/assessmentServiceNew"

/// I18N
import { useTranslation } from "react-i18next"
import "../../translations/i18n"

function Report({data}) {  

  /// Controle para liberar a atualização dos valores de rating e reactions
  const [init, setInit] = useState(false)

  /// Controls para os ratings e reactions para cada competencia
  const [reactionInovacao, setReactionInovacao] = useState(data.result.rating.reaction?.inovacao)
  const [ratingInovacao, setRatingInovacao] = useState(data.result.rating.ranking?.inovacao)  

  const [reactionLideranca, setReactionLideranca] = useState(data.result.rating.reaction?.lideranca)
  const [ratingLideranca, setRatingLideranca] = useState(data.result.rating.ranking?.lideranca)  

  const [reactionNegociacao, setReactionNegociacao] = useState(data.result.rating.reaction?.negociacao)
  const [ratingNegociacao, setRatingNegociacao] = useState(data.result.rating.ranking?.negociacao)  

  const [reactionColaboracao, setReactionColaboracao] = useState(data.result.rating.reaction?.colaboracao)
  const [ratingColaboracao, setRatingColaboracao] = useState(data.result.rating.ranking?.colaboracao)
  
  const [reactionPensamentoCritico, setReactionPensamentoCritico] = useState(data.result.rating.reaction?.pensamento_critico)
  const [ratingPensamentoCritico, setRatingPensamentoCritico] = useState(data.result.rating.ranking?.pensamento_critico)    

  const [reactionInteligenciaEmocional, setReactionInteligenciaEmocional] = useState(data.result.rating.reaction?.inteligencia_emocional)
  const [ratingInteligenciaEmocional, setRatingInteligenciaEmocional] = useState(data.result.rating.ranking?.inteligencia_emocional)    

  const [reactionOrientacaoParaServir, setReactionOrientacaoParaServir] = useState(data.result.rating.reaction?.orientacao_para_servir)
  const [ratingOrientacaoParaServir, setRatingOrientacaoParaServir] = useState(data.result.rating.ranking?.orientacao_para_servir)    

  const [reactionFlexibilidadeCognitiva, setReactionFlexibilidadeCognitiva] = useState(data.result.rating.reaction?.flexibilidade_cognitiva)
  const [ratingFlexibilidadeCognitiva, setRatingFlexibilidadeCognitiva] = useState(data.result.rating.ranking?.flexibilidade_cognitiva)    

  const [reactionJulgamentoTomadaDecisao, setReactionJulgamentoTomadaDecisao] = useState(data.result.rating.reaction?.julgamento_e_tomada_de_decisao)
  const [ratingJulgamentoTomadaDecisao, setRatingJulgamentoTomadaDecisao] = useState(data.result.rating.ranking?.julgamento_e_tomada_de_decisao)      

  const [reactionSolucaoProblemasComplexos, setReactionSolucaoProblemasComplexos] = useState(data.result.rating.reaction?.solucao_de_problemas_complexos)
  const [ratingSolucaoProblemasComplexos, setRatingSolucaoProblemasComplexos] = useState(data.result.rating.ranking?.solucao_de_problemas_complexos)        

  /// i18N
  const { t } = useTranslation();

  
  /// Sempre que um dos valores for alterado (rating ou reaction) é disparado a 
  /// atualização no backend. Como a atualização não é individual, tem que mandar todas sempre
  useEffect(() => {
    
    /// Esse init é usado para atualizar somente quando a atualização dos states for nos clicks da tela e não da inicialização os valores 
    if (init) {

      try {  
        /// Montando os dados para atualização no Backend

        /// Informação para o Ratings
        let ratingValues = {
          inovacao: ratingInovacao,
          lideranca: ratingLideranca,
          negociacao: ratingNegociacao,
          colaboracao: ratingColaboracao,
          pensamento_critico: ratingPensamentoCritico,
          inteligencia_emocional: ratingInteligenciaEmocional,
          orientacao_para_servir: ratingOrientacaoParaServir,
          flexibilidade_cognitiva: ratingFlexibilidadeCognitiva,
          julgamento_e_tomada_de_decisao: ratingJulgamentoTomadaDecisao,
          solucao_de_problemas_complexos: ratingSolucaoProblemasComplexos,
        }
        
        /// Informação para os Reactions
        let reactionsValues = {
          inovacao: reactionInovacao,
          lideranca: reactionLideranca,
          negociacao: reactionNegociacao,
          colaboracao: reactionColaboracao,
          pensamento_critico: reactionPensamentoCritico,
          inteligencia_emocional: reactionInteligenciaEmocional,
          orientacao_para_servir: reactionOrientacaoParaServir,
          flexibilidade_cognitiva: reactionFlexibilidadeCognitiva,
          julgamento_e_tomada_de_decisao: reactionJulgamentoTomadaDecisao,
          solucao_de_problemas_complexos: reactionSolucaoProblemasComplexos,
        }

        assessmentService.updateRatingReactions(data.code, ratingValues, reactionsValues).then((result) => {})
      } catch (e) {
        console.log("Erro", e)
      }       
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ratingInovacao, reactionInovacao,
    ratingLideranca, reactionLideranca,
    ratingNegociacao, reactionNegociacao,
    ratingColaboracao, reactionColaboracao,
    ratingPensamentoCritico, reactionPensamentoCritico,
    ratingInteligenciaEmocional, reactionInteligenciaEmocional,
    ratingOrientacaoParaServir, reactionOrientacaoParaServir,
    ratingFlexibilidadeCognitiva, reactionFlexibilidadeCognitiva,
    ratingJulgamentoTomadaDecisao, reactionJulgamentoTomadaDecisao,
    ratingSolucaoProblemasComplexos, reactionSolucaoProblemasComplexos
  ])  

  /// Render da Aplicacao
  return (
    <section className="assessment-report">
      <div className="container">
        <header>

        {/* , transform: 'rotate(-3deg) translateX(-40px)' */}
          <div
            style={{display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 60}}
          >
            <div
              style={{background: '#ff4d0d', position: 'relative', width: 360, height: 90, borderRadius: 10, transform: 'rotate(-3deg) translateX(-40px)', borderRight: '4px solid #c8421a', borderBottom: '4px solid #c8421a'}}
            >
              <span
                style={{position: 'absolute', left: 20, top: 5, color: '#FFED00', fontSize: 28, fontWeight: 700, letterSpacing: -0.5}}        
              >
                {t("report.relatorio_part1")}
              </span>
              <span
                style={{position: 'absolute', left: 20, top: 5, color: '#FFED00', marginTop: 18, fontSize: 48, fontWeight: 700, letterSpacing: -0.5}}        
              >
                {t("report.relatorio_part2")}
              </span>                  
            </div>
          </div>
          <div style={{marginTop: 30}} dangerouslySetInnerHTML={{__html: data.text_results}} />
        </header>

        <ul className="list-report" >
          <li>
            <CardReport  
              txtIdentification={t("report.identificacaoScore_desc")}
              txtRating={t("report.ratingScore_desc")}
              title={data.result.text.skill_header?.solucao_de_problemas_complexos?.name}
              subtitle={data.result.text.skill_header?.solucao_de_problemas_complexos?.desc}
              txtResult={data.result.text.skill_desc?.solucao_de_problemas_complexos}
              result={data.result.text.skill_percentile?.solucao_de_problemas_complexos.replace('%', '')}
              identification={reactionSolucaoProblemasComplexos}
              rating={ratingSolucaoProblemasComplexos}  
              onClickReaction={(value) => {setReactionSolucaoProblemasComplexos(value); setInit(true);}}
              onClickRating={(value) => {setRatingSolucaoProblemasComplexos(value); setInit(true);}}
            />
          </li>  

          <li>
            <CardReport  
              txtIdentification={t("report.identificacaoScore_desc")}
              txtRating={t("report.ratingScore_desc")}
              title={data.result.text.skill_header?.pensamento_critico?.name}
              subtitle={data.result.text.skill_header?.pensamento_critico?.desc}     
              txtResult={data.result.text.skill_desc?.pensamento_critico}                       
              result={data.result.text.skill_percentile?.pensamento_critico.replace('%', '')}
              identification={reactionPensamentoCritico}
              rating={ratingPensamentoCritico}    
              onClickReaction={(value) => {setReactionPensamentoCritico(value); setInit(true);}}
              onClickRating={(value) => {setRatingPensamentoCritico(value); setInit(true);}}                                                                                               
            />
          </li>     

          <li>
            <CardReport  
              txtIdentification={t("report.identificacaoScore_desc")}
              txtRating={t("report.ratingScore_desc")}
              title={data.result.text.skill_header?.inovacao?.name}
              subtitle={data.result.text.skill_header?.inovacao?.desc}
              txtResult={data.result.text.skill_desc?.inovacao}
              result={data.result.text.skill_percentile?.inovacao.replace('%', '')}
              identification={reactionInovacao}
              rating={ratingInovacao}
              onClickReaction={(value) => {setReactionInovacao(value); setInit(true);}}
              onClickRating={(value) => {setRatingInovacao(value); setInit(true);}}
            />
          </li>  

          <li>
            <CardReport  
              txtIdentification={t("report.identificacaoScore_desc")}
              txtRating={t("report.ratingScore_desc")}
              title={data.result.text.skill_header?.lideranca?.name}
              subtitle={data.result.text.skill_header?.lideranca?.desc}
              txtResult={data.result.text.skill_desc?.lideranca}
              result={data.result.text.skill_percentile?.lideranca.replace('%', '')}
              identification={reactionLideranca}
              rating={ratingLideranca}
              onClickReaction={(value) => {setReactionLideranca(value); setInit(true);}}
              onClickRating={(value) => {setRatingLideranca(value); setInit(true);}}
            />
          </li>          

          <li>
            <CardReport  
              txtIdentification={t("report.identificacaoScore_desc")}
              txtRating={t("report.ratingScore_desc")}
              title={data.result.text.skill_header?.colaboracao?.name}
              subtitle={data.result.text.skill_header?.colaboracao?.desc}
              txtResult={data.result.text.skill_desc?.colaboracao}
              result={data.result.text.skill_percentile?.colaboracao.replace('%', '')}
              identification={reactionColaboracao}
              rating={ratingColaboracao}            
              onClickReaction={(value) => {setReactionColaboracao(value); setInit(true);}}
              onClickRating={(value) => {setRatingColaboracao(value); setInit(true);}}                                                                      
            />
          </li>    

          <li>
            <CardReport  
              txtIdentification={t("report.identificacaoScore_desc")}
              txtRating={t("report.ratingScore_desc")}
              title={data.result.text.skill_header?.inteligencia_emocional?.name}
              subtitle={data.result.text.skill_header?.inteligencia_emocional?.desc}
              txtResult={data.result.text.skill_desc?.inteligencia_emocional}                                     
              result={data.result.text.skill_percentile?.inteligencia_emocional.replace('%', '')}
              identification={reactionInteligenciaEmocional}
              rating={ratingInteligenciaEmocional}        
              onClickReaction={(value) => {setReactionInteligenciaEmocional(value); setInit(true);}}
              onClickRating={(value) => {setRatingInteligenciaEmocional(value); setInit(true);}}                                                                                         
            />
          </li>                  


          <li>
            <CardReport  
              txtIdentification={t("report.identificacaoScore_desc")}
              txtRating={t("report.ratingScore_desc")}
              title={data.result.text.skill_header?.julgamento_e_tomada_de_decisao?.name}
              subtitle={data.result.text.skill_header?.julgamento_e_tomada_de_decisao?.desc}
              txtResult={data.result.text.skill_desc?.julgamento_e_tomada_de_decisao}
              result={data.result.text.skill_percentile?.julgamento_e_tomada_de_decisao.replace('%', '')}
              identification={reactionJulgamentoTomadaDecisao}
              rating={ratingJulgamentoTomadaDecisao}            
              onClickReaction={(value) => {setReactionJulgamentoTomadaDecisao(value); setInit(true);}}
              onClickRating={(value) => {setRatingJulgamentoTomadaDecisao(value); setInit(true);}}                                                                                       
            />
          </li>        
         
          
     
          
         
          
          <li>
            <CardReport  
              txtIdentification={t("report.identificacaoScore_desc")}
              txtRating={t("report.ratingScore_desc")}
              title={data.result.text.skill_header?.orientacao_para_servir?.name}
              subtitle={data.result.text.skill_header?.orientacao_para_servir?.desc}
              txtResult={data.result.text.skill_desc?.orientacao_para_servir}                                     
              result={data.result.text.skill_percentile?.orientacao_para_servir.replace('%', '')}
              identification={reactionOrientacaoParaServir}
              rating={ratingOrientacaoParaServir}           
              onClickReaction={(value) => {setReactionOrientacaoParaServir(value); setInit(true);}}
              onClickRating={(value) => {setRatingOrientacaoParaServir(value); setInit(true);}}                                                                                        
            />
          </li>          
          
          
          <li>
            <CardReport  
              txtIdentification={t("report.identificacaoScore_desc")}
              txtRating={t("report.ratingScore_desc")}
              title={data.result.text.skill_header?.negociacao?.name}
              subtitle={data.result.text.skill_header?.negociacao?.desc}
              txtResult={data.result.text.skill_desc?.negociacao}
              result={data.result.text.skill_percentile?.negociacao.replace('%', '')}
              identification={reactionNegociacao}
              rating={ratingNegociacao}          
              onClickReaction={(value) => {setReactionNegociacao(value); setInit(true);}}
              onClickRating={(value) => {setRatingNegociacao(value); setInit(true);}}                               
            />
          </li>  

          <li>
            <CardReport  
              txtIdentification={t("report.identificacaoScore_desc")}
              txtRating={t("report.ratingScore_desc")}
              title={data.result.text.skill_header?.flexibilidade_cognitiva?.name}
              subtitle={data.result.text.skill_header?.flexibilidade_cognitiva?.desc}
              txtResult={data.result.text.skill_desc?.flexibilidade_cognitiva}    
              result={data.result.text.skill_percentile?.flexibilidade_cognitiva.replace('%', '')}
              identification={reactionFlexibilidadeCognitiva}
              rating={ratingFlexibilidadeCognitiva}             
              onClickReaction={(value) => {setReactionFlexibilidadeCognitiva(value); setInit(true);}}
              onClickRating={(value) => {setRatingFlexibilidadeCognitiva(value); setInit(true);}}                                                                                      
            />
          </li>          
          
  

                  
        </ul>

      </div>
    </section>
  );
}

export default Report;