import React, { useEffect, useState } from "react";

/// Import Assessment Report Result
import "./Report.scss";
import Loading from "../../../components/reportResult/Loading";
import ReportContent from "./ReportContent";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import AssessmentSuccess from "../../../components/assessmentSuccess";

/// Service
import assessmentService from "../../../services/assessmentServiceNew";

/// I18N
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import i18n from "i18next";
import NewReport from "./newReport/NewReport";
import { getDataFromParent } from "iframe-communication";

const organizationsBlock = [
  // "66a13520-add3-46dd-a273-7ee99e10893b",
  // "060c3beb-824c-4a83-a0a5-33745086b001",
];

function Report({ props }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id, version } = useParams();
  const [userData, setUserData] = useState(null);
  const { t } = useTranslation();
  const [Block, setBlock] = useState(false);
  const [AssessmentVersion, setAssessmentVersion] = useState(version);

  function updateUser(user) {
    let userTempData = user?.userData;
    userTempData.assessment_date = user?.assessment_date;
    userTempData.deimos_token = user?.token;
    userTempData.language =
      userTempData?.language === 'en-us' ? 'en-US' : (userTempData?.language === 'es-es' ? 'es' : 'pt-BR');

    window.localStorage.setItem("userData", JSON.stringify(userTempData));
    setUserData(userTempData);
  }

  // eslint-disable-next-line
  const [assessmentSession, setAssessmentSession] = useState();

  useEffect(() => {
    if (userData) {
      const fetchData = async () => {
        try {
          /// Carregando o dado com o valor da variável passado na URL (router :id)
          const resultSession = await assessmentService.getAssessmentSession(
            userData?.uuid
          );

          let verificandoPermissao = resultSession.data.filter((item) => {
            return id === item.assessment_code;
          });

          if (verificandoPermissao.length === 0) {
            setLoading(false);
            throw new Error("Não é possível acessar esse assessment.");
          }

          let sessionResult = resultSession.data.filter((item) => {
            return (
              item.session_status === "close" &&
              item.company_code === userData?.current_group?.root?.uuid
            );
          });

          setAssessmentSession(sessionResult);

          const result = await assessmentService.getAssessmentReport(id);

          if (result.data === undefined) {
            setLoading(false);
            throw new Error("Assessment not found.");
          }

          if (
            organizationsBlock.includes(userData?.current_group?.root?.uuid) &&
            result.data.assessment_type === "r"
          ) {
            setBlock(true);
          }

          /// Setando o idioma do PDF em todas os textos do report
          // i18n.changeLanguage(localeDict[result.data.language])
          let assessmentReuslt = result.data;

          if (AssessmentVersion === "undefined") {
            setAssessmentVersion(assessmentReuslt.result.pdf_version);
          } else if (assessmentReuslt.result.text.trait_result === undefined) {
            setAssessmentVersion("pdf_v2");
          }

          setData(result.data);
          setLoading(false);
        } catch (e) {
          toast.error(t("report.errorData"));
        } finally {
          setLoading(false);
        }
      };
      if (userData) {
        i18n.changeLanguage(userData.language);
      } else {
        i18n.changeLanguage("pt-BR");
      }

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    setTimeout(() => {
      getDataFromParent("user", updateUser);
    }, 1000);
  }, []);

  return (
    <>
      {userData === null || loading ? (
        <div style={{ height: 1000 }}>
          <Loading showLoading={true} />
        </div>
      ) : Block ? (
        <div className="main-wrapper">
          <div className="main-container">
            <div className="main-container-inner">
              <AssessmentSuccess
                assessmentHome={true}
                assessmentCode={id}
                assessmentTaskStatus={"success"}
                assessmentType={data.assessment_type === "a" ? 1 : 2}
                counter={0}
                organization={
                  userData?.current_group?.root?.uuid
                    ? userData?.current_group?.root?.uuid
                    : userData?.current_group?.root?.uuid
                }
              />
            </div>
          </div>
        </div>
      ) : data ? (
        AssessmentVersion === "pdf_v2" || AssessmentVersion === "pdf_v1" ? (
          <ReportContent data={data} />
        ) : (
          <>
            <NewReport data={data} assessmentSession={assessmentSession} />
          </>
        )
      ) : (
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 400,
              width: "100%",
            }}
          >
            <span
              style={{
                justifyContent: "center",
                alignItems: "center",
                color: "#ff4d0d",
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              {t("report.assessmentNotFound")}
            </span>
          </div>
        </>
      )}
    </>
  );
}

export default Report;
