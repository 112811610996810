import React from 'react';
import './index.css'

function PercentageBarResult ({textInfo1, percentage1, textInfo2, percentage2}) {
    return(
        <div className='container-bar' id="container-bar">
            <span className='text-info' style={{fontWeight: percentage1 > percentage2 ? '700' : '400', color: percentage1 > percentage2 ? '#398787' : '#222222', textAlign: 'right'}}>
                {textInfo1}
            </span>
            <div className='percentage-bar' id='pecentage-bar'>
                <div className='percentage-text' id='percentage-result' style={{color: percentage1 > percentage2 ? '#398787' : '#64748B'}}>
                    {percentage1}%
                </div>
                <div className='bar'>
                    <div className='bar-partition1' id='bar-partition-one' style={{width: `${percentage1}%`, backgroundColor: percentage1 > percentage2 ? '#398787' : '#D1D5DB'}}></div>
                    <div className='bar-partition2' id='bar-partition-two' style={{width: `${percentage2}%`, backgroundColor: percentage2 > percentage1 ? '#398787' : '#D1D5DB'}}></div>
                </div>
                <div className='percentage-text'  id='percentage-result' style={{color: percentage2 > percentage1 ? '#398787' : '#64748B'}}>
                    {percentage2}%
                </div>
            </div>
            <div className='text-info' id='text-info' style={{fontWeight: percentage2 > percentage1 ? '700' : '400', color: percentage2 > percentage1 ? '#398787' : '#222222'}}>
                {textInfo2}
            </div>
        </div>
    )
}

export default PercentageBarResult;