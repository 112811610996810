import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, notification } from 'antd';
import _lodash from 'lodash';
import AssessmentProgressBar from '../progress';
import './index.css';
import { useTranslation } from "react-i18next"
import "../../translations/i18n"
import parse from 'html-react-parser';

const { TextArea } = Input;
const { REACT_APP_BLOCK_TEXT_PAGE } = process.env;

const TextPage = (props) => {
    const [textTitle, setTextTitle] = useState(props.assessment.form?.wording?.title)
    const [textEssay, setTextEssay] = useState(props.assessment.form?.wording?.body)
    const [wordsPercentage, setWordsPercentage] = useState(props.wordCount * 100 / (props.assessment.form?.config.word_min_count * 2))
    const [isValidToSend, setIsValidToSend] = useState(false)

    const [digitando, setDigitando] = useState(false)
  
    // const minimumWords = props.assessment.form.config.word_min_count;
    const minimumWords = props.assessment.form?.config.word_min_count
    const blockTextPage = REACT_APP_BLOCK_TEXT_PAGE === 'true';
    const doneTypingInterval = 3000; 
    var typingTimer; 
  
    const { t } = useTranslation();
    
    useEffect(() => {
      setIsValidToSend(textTitle && props.wordCount >= minimumWords)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textTitle, props.wordCount])
  
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
      setWordsPercentage(props.wordCount * 100 / (props.assessment.form?.config.word_min_count * 2))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.wordCount])
  
    /// ------------------------------------------------------------------------
    /// Return
    /// ------------------------------------------------------------------------
    return (

      <div id="container-title">
        <h1 className="title">{parse(t("text.title"))}</h1>

        <Form layout="vertical"> 
          <Row style={{ marginBottom: 0, paddingBottom: 0}}>
            <Col span={24}>
              <Form.Item label={parse(t("text.InputTituloLabel"))} style={{ fontWeight: 'bold', width: "100%" }} rules={[{ required: true, message: parse(t("text.InputRequired")) }]}>
                <Input 
                  type="text" 
                  placeholder={parse(t("text.InputTitulo"))} 
                  value={textTitle} 
                  onChange={e => setTextTitle(e.target.value)} 
                  onPaste={(e) => { 
                    if (blockTextPage) { 
                      notification['warning']({
                        message: parse(t("general.alertCopyPasteTitle")),
                        description: parse(t("general.alertCopyPasteText"))
                      }); e.preventDefault();                               
                    }}}
                    id="input-text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item style={{ fontWeight: 'bold', width: "100%" }} >
                <TextArea 
                  id="text-essay"
                  rows={6} 
                  value={textEssay} 
                  onChange={(evt) => {
                    setTextEssay(evt.target.value)
                  }} 
                  onKeyUp={() => {
                    clearTimeout(typingTimer);
                    typingTimer = setTimeout(() => {
                      props.onSave(textTitle, textEssay)
                      setTimeout(() => {
                        setDigitando(false)
                      }, 500)
                    }, doneTypingInterval);
                  }}
                  onKeyDown={() => {
                    clearTimeout(typingTimer);
                    setDigitando(true)
                  }}
                  onPaste={(e) => { 
                    if (blockTextPage) { 
                      notification['warning']({
                        message: parse(t("general.alertCopyPasteTitle")),
                        description: parse(t("general.alertCopyPasteText"))
                      }); e.preventDefault();                               
                    }}} 
                />
              </Form.Item>
            </Col>
          </Row>
          
          <Row justify="end" style={{marginTop: -35}}>
            <Col className="assessment-progress-bar-content">
              <AssessmentProgressBar completed={wordsPercentage} />
              {
                minimumWords - props.wordCount > 0 ?
                <div 
                  style={{ textAlign: 'right', fontSize: 13 }}
                >
                  {parse(t("general.minimumWords1"))} 
                  <span style={{ color: '#ff8559', fontWeight: "bold"}}>
                    {digitando ?
                      <>
                        <div className="loader"></div>
                      </>
                    :
                      `${minimumWords - props.wordCount < 0 ? 0 : minimumWords - props.wordCount} ${parse(t("general.minimumWords2"))} `
                    }
                  </span> 
                  {parse(t("general.minimumWords3"))} 
                </div>
                :
                digitando &&
                <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>                
                  <div className="loader" ></div>
                </div>                             
              }  
              </Col>
          </Row>

          <div style={{ marginTop: "10px"}}>
            <Button 
              htmlType="button" 
              size="large" 
              className="btn-big btn-orange mr-3 question-text-button" 
              onClick={() => {
                props.onBack()
              }}
              id="button-back"
            >
              {parse(t("general.buttomBack"))} 
            </Button>

            {
              _lodash.words(textEssay).length > 1 &&
                <Button 
                  type="link" 
                  disabled={props.loading || digitando} 
                  className="btn-link question-text-button-link" 
                  onClick={() => {
                    props.onSave(textTitle, textEssay)
                  }} 
                  style={{ paddingLeft: 0, marginTop: "8px" }}
                  id='text-buttonSaveLater'
                >
                  {parse(t("text.buttomSaveLater"))}                              
                </Button>
            }       

            <Button 
              htmlType="button" 
              size="large" 
              className="btn-big btn-orange question-text-button-finish" 
              disabled={!isValidToSend || props.loading} 
              onClick={() => {
                props.onFinish(textTitle, textEssay)
              }} 
              id="buttom-send-text"
            >
              {parse(t("general.buttomSendText"))} 
            </Button>
          </div>

        </Form>
      </div>
          
    );
  }
  
  export default TextPage;
