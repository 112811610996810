import axios from "axios"

export const isAuthenticated = () => {  

  if (window.localStorage.getItem('hallAccess') === "true") {
    return false
  }
  else {
    const authority = JSON.parse(window.localStorage.getItem('authority'))
    return authority && authority.token ? true : false
  }  
}

export const login = async (email, password) => {
  const url = `${process.env.REACT_APP_API_REGISTER_BASE_URL}/api/v1/users/login`;

  return await axios.post(url, { email, password })
    .then(res => res)
    .catch(err => err)
}

export const getCurrentUser = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData'))
  return userData;
}

const authService = {
  isAuthenticated,
  login,
  getCurrentUser
}

export default authService;