import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import appConfig from "../config/appConfig.json";

// ERROR
import UserRedirect from "../pages/entry-point/redirect-page";
import ErrorPage from "../pages/entry-point/error-page";

import AuthPage from "../pages/auth";
import {
  HallRunAssessmentPage,
  HallRunReassessmentPage,
} from "../pages/user/runAssessment";
import Report from "../pages/user/report";

export default function Routes() {

  return (
    <BrowserRouter>
      <Switch>
        {/* ROTAS NAO SEGURAS  */}

        {/* Rotas Públicas */}
        <Route
          exact
          path="/nova-rota/:role/:path/:token"
          component={UserRedirect}
        />

        <Route
          exact
          path={appConfig.appRoutes.loginPath}
          component={AuthPage}
        />

        {/* Rotas do Hall */}
        <Route
          exact
          path={appConfig.appRoutes.hall_RunAssessmentPath}
          component={HallRunAssessmentPage}
        />

        <Route
          exact
          path={appConfig.appRoutes.hall_RunReassessmentPath}
          component={HallRunReassessmentPage}
        />

        <Route
          exact
          path={appConfig.appRoutes.hall_assessmentReportResult}
          component={Report}
        />

        <Redirect from="/" to={appConfig.appRoutes.userRunAssessmentPath} />

        <Route exact path="*" component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
}
