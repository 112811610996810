import axios from "axios";
import BackendTokenService from "./BackendTokenService";
// import TokenService from "./TokenService";

/* Tested */
export const getAssessmentReport = async (assessmentCode) => {
  const url = `${process.env.REACT_APP_ASSESSMENT_NEW}/assessment?code=${assessmentCode}`;

  const configApiAssessment = {
    headers: {
      Authorization: `Token ${await BackendTokenService.getToken()}`,
    },
  };

  return await axios
    .get(url, configApiAssessment)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

/* Tested */
export const updateRatingReactions = async (
  assessmentCode,
  rating,
  reaction
) => {
  const url = `${process.env.REACT_APP_ASSESSMENT_NEW}/assessment-rating`;

  const configApiAssessment = {
    headers: {
      Authorization: `Token ${await BackendTokenService.getToken()}`,
    },
  };

  let data = {
    assessment_code: assessmentCode,
    rating: {
      ranking: rating,
      reaction,
    },
  };
  return await axios
    .post(url, data, configApiAssessment)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

/* okay */
export const getAssessmentSession = async (userId) => {
  const url = `${process.env.REACT_APP_ASSESSMENT_NEW}/assessment-session?user_external_id=${userId}`;

  const configApiAssessment = {
    headers: {
      Authorization: `Token ${await BackendTokenService.getToken()}`,
    },
  };

  return await axios
    .get(url, configApiAssessment)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
/* okay */
export const setAssessmentSession = async (
  name,
  email,
  user_external_id,
  company_name,
  company_code,
  role_name,
  role_code,
  area_name,
  area_code,
  language,
  assessment_type,
  session_type
) => {
  const url = `${process.env.REACT_APP_ASSESSMENT_NEW}/assessment-session`;

  const configApiAssessment = {
    headers: {
      Authorization: `Token ${await BackendTokenService.getToken()}`,
    },
  };
  
  let data = {
    // name,
    // email,
    // user_external_id,
    // company_name,
    group_uuid: company_code,
    // role_name,
    // role_code,
    // area_name,
    // area_code,
    language,
    assessment_type,
    session_type,
  };

  return await axios
    .post(url, data, configApiAssessment)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

/* okay */
export const setAssessment = async (assessment) => {
  const url = `${process.env.REACT_APP_ASSESSMENT_NEW}/assessment-session-form`;

  const configApiAssessment = {
    headers: {
      Authorization: `Token ${await BackendTokenService.getToken()}`,
    },
  };

  return await axios
    .post(url, assessment, configApiAssessment)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

/* okay */
export const reset = async (assessment) => {
  const url = `${process.env.REACT_APP_ASSESSMENT_NEW}/assessment-session-reset`;

  const configApiAssessment = {
    headers: {
      Authorization: `Token ${await BackendTokenService.getToken()}`,
    },
  };

  return await axios
    .post(url, assessment, configApiAssessment)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getPermission = async (userId) => {
  const url = `${process.env.REACT_APP_ASSESSMENT_MASTER_URL}/api/v1/users/assessment/${userId}`;

  const configApiAssessment = {
    headers: {
      Authorization: `Token ${await BackendTokenService.getToken()}`,
    },
  };

  return await axios
    .get(url, configApiAssessment)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getInfosComplementares = async (userId, token) => {
  const url = `${process.env.REACT_APP_DEIMOS}/api/v1/customer-users/${userId}`;

  const configApiDeimos = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return await axios
    .get(url, configApiDeimos)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const setAssessmentExperience = async (
  tags,
  opinion,
  assessment_code
) => {
  const url = `${process.env.REACT_APP_ASSESSMENT_NEW}/assessment-experience/`;

  const configApiAssessment = {
    headers: {
      Authorization: `Token ${await BackendTokenService.getToken()}`,
    },
  };

  const data = {
    tags: tags,
    opinion: opinion,
    assessment_code: assessment_code,
  };

  return await axios
    .post(url, data, configApiAssessment)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const setLanguage = async (userId, language, token) => {
  const url = `${process.env.REACT_APP_DEIMOS}/api/v1/customer-users/${userId}`;

  const data = {
    language: language
  };

  const configApiDeimos = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return await axios.put(url, data, configApiDeimos)
    .then(res => {
      return res
    })
    .catch(err => {
      return err
    })
}


export const assessmentPDFDownloadEmail = async (group_uuid, link) => {
  const url = `${process.env.REACT_APP_ASSESSMENT_NEW}/company-report-user`;

  const configApiAssessment = {
    headers: {
      Authorization: `Token ${await BackendTokenService.getToken()}`,
    },
  };

  const data = {
    group_uuid:group_uuid,
    link:link
  }

  try {
    const response = await axios.post(url, data, configApiAssessment);
    console.log("res", response);
    return response;
  } catch (error) {
    console.error("Error during PDF download:", error);
    throw error;
  }
};

const assessmentService = {
  getAssessmentReport,
  updateRatingReactions,
  getAssessmentSession,
  setAssessmentSession,
  setAssessment,
  getPermission,
  getInfosComplementares,
  reset,
  setAssessmentExperience,
  assessmentPDFDownloadEmail
};

export default assessmentService;
