import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, notification } from 'antd';
import authService from '../../services/authService';
import LogoMenu from '../../assets/images/frst-logo.png';
import './index.css';

const AuthPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    if (window.localStorage.getItem("hallAccess") === "true") {
      window.localStorage.clear()
      window.localStorage.setItem("hallAccess", "false")
      window.location.href = "/"
    }
    else {
      window.localStorage.clear()
      window.localStorage.setItem("hallAccess", "false")
    }
    
  }, []);

  const [form] = Form.useForm();

  async function handleFinish(obj) {
    setIsSubmitting(true);
    setLoading(true);

    try {
      if (obj.email && obj.password) {
        const result = await authService.login(obj.email, obj.password);
        if (
          result.status === 200 &&
          result.data &&
          !result.data.message
        ) {
          const user = result.data;
          user.roles = ["manager_user"];
          window.localStorage.setItem('user', JSON.stringify(user));

          // mock
          const token = process.env.REACT_APP_API_ASSESSMENT_TOKEN;
          window.localStorage.setItem('authority', JSON.stringify({ token: token }));
          window.localStorage.setItem("hallAccess", "false");
          window.location.href = '/user/run-assessment';
        }
        else {
          notification['warning']({
            message: 'Aviso!',
            description: 'E-mail ou senha incorretos!'
          });

          setIsSubmitting(false);
        }
      }
      else {
        notification['warning']({
          message: 'ERROR 400',
          description: 'E-mail e senha são obrigatórios.'
        });

        setIsSubmitting(false);
      }
      setLoading(false);
    }
    catch (error) {
      notification['error']({
        message: 'ERROR',
        description: error.message
      });

      setIsSubmitting(false);
      setLoading(false);
    }
  }

  return (
    <div className="login-container-wrapper">
      <div className="login-container">
        <div className="login-container-content">
          <div className="logo-wrapper">
            <img src={LogoMenu} alt="frst-logo" />
          </div>
          <Form form={form}
            layout="vertical"
            onFinish={handleFinish}>
            <Row>
              <Col span={24}>
                <Form.Item name={['email']} label="E-mail"
                  rules={[{
                    required: true,
                    message: "O campo e-mail é obrigatório."
                  }]}
                >
                  <Input
                    placeholder="Seu e-mail"
                    type="email"
                    data-cy="ipt-email-login"
                  />
                </Form.Item>
                <Form.Item name={['password']} label="Senha"
                  rules={[
                    {
                      required: true,
                      message: "O campo senha é obrigatório."
                    }]}
                >
                  <Input
                    placeholder="Sua senha"
                    type="password"
                    data-cy="ipt-password-login"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item>
                  <Button data-cy="btn-login" type="primary" htmlType="submit" block size="large" loading={loading} disabled={isSubmitting}>Continuar</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AuthPage;