import React, {useState} from "react";

import './HowToText.scss';
import { useTranslation } from "react-i18next";

import arrow from "../../../assets/img/ico-seta.svg";

function HowToText({showMore, children}) {
  const [step, setStep] = useState(1);
  const {t} = useTranslation()

  return (
    <div className={`${step === 2 ? "step2" : ""}`}>
      { children }
      {showMore && step === 1 ?
        <button style={{background: 'none', border: 'none'}} onClick={()=>setStep(2)}>{t('continue')} <img src={arrow} alt={t('continue')}/></button>
        : <button style={{background: 'none', border: 'none'}} className="prev" onClick={()=>setStep(1)}><img src={arrow} alt={t('back')}/>{t('back')}</button>
      }
    </div>
  );
}

export default HowToText;