import React, { Fragment } from 'react';
import { Result } from 'antd';

const Page = () => {
    
    return (
        <Fragment>

            <Result
                style={{ marginTop: '100px' }}
                status="500"
                title="500"
                subTitle="Desculpe, essa página não existe."
                // extra={<Button type="primary">Back Home</Button>}
            />

        </Fragment>
    );
}

export default Page;