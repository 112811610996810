export const TRANSLATIONS_EN = {
  runAssessment: {
    /// Titulo
    title: "Are you ready to know yourself?",
    titleDesc: "Follow the instructions below and have a great Assessment!",
    titleDesc_2:
      "Follow the instructions below and have a great mapa de autoconhecimento!",

    /// Lista de como fazer o assessment
    listOption1:
      "It takes about 40 minutes to complete the <b>mapa de autoconhecimento</b>.",
    listOption1_2:
      "It takes about 40 minutes to complete the <b>mapa de autoconhecimento</b>.",
    listOption2: "Find a quiet place and make sure no one interrupts you.",
    listOption3:
      "You must write a text about your professional career, exploring your goals, choices, learnings, realizations and future expectations. You can choose between two ways of text production: <br> <b>writing an essay</b> or <b>answering questions</b>.",
    listOption3_2:
      "You must write a text about your development journey so far - the lessons learned and the results achieved - and how the skills worked have helped you in your daily challenges and how they connect with your future professional goals. You can choose between two ways of text production: <br> <b>writing an essay</b> or <b>answering questions</b>.",
    listOption4:
      "In both options, you will need to write at <b>least 600 words</b>, but our suggestion is that you complete 1200 words. Importante: if you select <b>the answer questions option</b>, you don’t need to complete 600 words in a single question - you can answer many questions until you reach this quantity. Also, if you don't want to answer a question, you can skip it.",
    listOption5:
      "Remember it: <b>there are no right or wrong answers</b>. The FRST Assessment does not assess context, grammatical errors or how many times you have restarted your writing.",
    listOption5_2:
      "Remember it: <b>there are no right or wrong answers</b>. The FRST mapa de autoconhecimento does not assess context, grammatical errors or how many times you have restarted your writing.",
    listOption6:
      "The application will activate the <b>Submit button</b> when you reach the minimum number of words to complete the FRST Assessment. Just click when you're ready.",
    listOption6_2:
      "The application will activate the <b>Submit button</b> when you reach the minimum number of words to complete the FRST mapa de autoconhecimento. Just click when you're ready.",

    listOption7:
      "After submitting your answers, our artificial intelligence algorithm kicks in. It will analyze your text and diagnose your main development opportunities.",

    listOption8:
      "You will be able to view the individual result of the FRST Assessment online and will receive a copy of the report by email.",
    listOption8_2:
      "You will be able to view the individual result of the FRST mapa de autoconhecimento online and will receive a copy of the report by email.",

    ///
    assessmentExpireAtPart1: "You must complete your Assessment in up to: ",
    assessmentExpireAtPart1_2:
      "You must complete your mapa de autoconhecimento in up to: ",
    assessmentExpireAtPart2: " day(s)",

    ///
    assessmentContinue:
      "You have already started your Assessment, do you want to continue it?",
    assessmentContinue_2:
      "You have already started your mapa de autoconhecimento, do you want to continue it?",
    assessmentChooseOption:
      "Select below which method do you prefer to complete:",

    /// Botões
    buttomAssessmentText: "Write an essay",
    buttomAssessmentAnswerAgain: "Answer again",
    buttomAssessmentAnwserQuestion: "Answer questions",
    buttomResetAssessment: "No, restart Assessment",
    buttomResetAssessment_2: "No, restart mapa de autoconhecimento",
    buttomContinueAssessment: "Yes, I want to continue it",
    buttomFinish: "Finish",

    /// Modal de Reset do Assessment
    modalResetAssessmentTitle: "Reset Assessment",
    modalResetAssessmentOk: "Confirm",
    modalResetAssessmentCancel: "No",

    modalResetAssessmentTextLanguage:
      "If you change the Language, you have to restart your Assessment.",
    modalResetAssessmentText1: "Are you sure you want to restart?",
    modalResetAssessmentText2:
      "If you select the restart Assessment option you will lose all the data saved so far but you will be able to choose again between writing an essay or answering questions.",
    modalResetAssessmentText3:
      "Do you confirm that you want to proceed and erase all data?",

    /// Loading
    loading: "Loading FRST Assessment",
    loading_2: "Loading FRST mapa de autoconhecimento",

    /// Permissão
    permissionTitle: "mapa de autoconhecimento",
    permissionTitle_2: "mapa de autoconhecimento",
    permissionText:
      "There are currently no Assessments available for you to take.",
    permissionText_2:
      "There are currently no Reassessments available for you to take.",
    permissionBack: "Back to Home.",

    /// Alert Message
    alertResetAssessmentSuccessTitle: "Success",
    alertResetAssessmentSuccessText: "Restarted Assessment",

    /// Alert Error
    alertResetAssessmentErrorTitle: "Error",
    alertResetAssessmentErrorText: "Error restarting Assessment.",
    alertResetReassessmentErrorText:
      "Error restarting mapa de autoconhecimento.",
  },
  general: {
    /// ProgressBar
    minimumWords1: "",
    minimumWords2: "words",
    minimumWords3: "words remaining to reach the minimum necessary",

    /// Alert Message
    alertCopyPasteTitle: "Warning",
    alertCopyPasteText: "Cannot copy / paste.",

    /// Buttom
    buttomBack: "Back",
    buttomSkipQuestion: "or skip question",
    buttomSendText: "Submit response",

    /// Loading
    loadingStatus: "Loading information",

    /// Alert Success
    alertResetAssessmentSuccessTitle: "Success",
    alertResetAssessmentSuccessText: "Restarted Assessment",
    alertResetReassessmentSuccessText: "Restarted mapa de autoconhecimento",

    /// Alert Error
    alertErrorTitle: "Error",
    alertSuccessTitle: "Success",
    alertWarningTitle: "Warning",

    questaoNaoRespondida: "You must answer the question to submit!",
  },
  text: {
    /// Titulo
    title: "Essay for social-emotional evaluation",
    title2: "Questions for social-emotional evaluation",
    titleMinimumWords: "Minimum number of words not reached.",
    titleDesc: "Follow the instructions below and good Assessment.",

    ///
    textMinimumWords:
      "You did not reach the minimum number of words needed. <br>Answer the next questions to complete your Assessment.",

    /// Loading
    loadingStatus: "Loading information",
    loading: "Loading Essay",
    loading2: "Loading Questions",

    questionText: "Questions",
    questionParte: "of",
    questionMinimumWords: "typed word(s)",
    questionMinimumWords2: "of at least",
    questionMinimumWords3: "per answer.",

    warningTextMinimumWords:
      "All questions were answered and we did not reach the minimum number of words required. <br> To have a more assertive result, restart Assessment and",
    warningTextMinimumWords2: "fill in the minimum of 600 words",
    warningTextMinimumWords3: "to complete correctly.",

    /// Validation &  Input
    InputRequired: "Title field is required.",
    InputTituloLabel: "Title for the text",
    InputTitulo: "🖉 Title",

    buttomFinish: "Finish",

    /// Buttom
    buttomSaving: "Saving...",
    buttomSaveLater: "or Save to continue later",
    buttomSending: "Sending...",
    buttomSubmitForEvaluation: "Submit for evaluation",

    /// Alert Success
    alertSaveTextSuccess: "Essay saved successfully",
    alertSaveQuestionSuccess: "Response registered",
    alertSendTextSuccess: "Essay sent successfully",

    /// Alert Warning
    alertTitleWarning:
      "You need to enter the title of the text. Don't worry, you can change it later if you like.",

    /// Alert Error
    alertGetTextError: "Error getting this user's essay",
    alertSaveTextError: "Error saving this user's essay",
    alertAssessmentError: "Error getting this user's assessment",
    alertCreateAssessmentError: "Error creating/updating Assessment.",
    alertSendEssayError: "Error sending Essay.",

    /// Modal de alerta de poucas palavras
    alertTitleMinimumWords: "To advance your Assessment you need:",
    alertTextMinimumWords:
      "<li>Write at least <b>600 words to complete this step</b>.</li><li>The longer the text, the more accurate the analysis of your result will be.</li><li>The questions are limited, so it is critical that you write an average of 20 words for each question.</li>",
  },
  success: {
    text: "You have already completed the ",
    text2: " submitted successfully",

    status1: "Sending data",
    status2: "Processing results",

    loading: "Loading result",

    textDownload: "Click on the button below to see the result ",
    buttomDownload: "See my result",

    messageAssessmentDuration:
      "Please wait, your Assessment is being processed.<br>The file will be available for download within 5 minutes.",

    messageDexco:
      "Your assessment has been successfully completed. We are generating your result and it will soon be available for download.<br><br>If you have any questions, get it from Dexco's People - DHO team.",
  },
  report: {
    errorData: "Error loading data, please try again later!",
    downloadText: "Click the button below to download your result.",
    downloadButton: "Download Result",
    profissionalFuturo: "PROFESSIONAL OF THE FUTURE",
    assessmentText:
      "The FRST Assessment process is very simple.<br/> It uses Artificial Intelligence to understand your socio-emotional profile and correlates with each individual's skills development opportunities.",

    comoFunciona1: "How",
    comoFunciona2: "IT WORKS?",

    caractSocioemocionais_title: "Socioemotional Characteristics",
    caractSocioemocionais_desc:
      "We use personal text written by you to identify your characteristics.",

    intArtificial_title: "Artificial intelligence",
    intArtificial_desc:
      "The FRST Assessment begins the work of cognitive computing with the objective of correlating its characteristics with the skills of the professional of the future.",

    competencias_title: "Skills",
    competencias_part1_desc:
      "With the assessment result in hand, you will be able to view the competencies present in your profile with greater or lesser intensity.",
    competencias_part2_desc:
      "It will be possible to recognize behavioral traits and tendencies, develop an action plan to strengthen some competence and even use it in career mentoring sessions.",

    trilha_title: "Skills",
    trilha_desc:
      "After checking the skills gaps, we offer a personalized learning path for you, made up of modules associated with your development needs.",

    evolucao_title: "Evolution",
    evolucao_desc:
      "At the end of the program, you carry out a new FRST Assessment where more information is collected and, thus, we are able to measure your progress.",

    relatorio_part1: "Competences",
    relatorio_part2: "Report",

    identificacaoScore_desc:
      "<strong>Do you identify with this result?<br/> Choose how you recognize yourself in it.</strong>",
    ratingScore_desc:
      "<strong>Overall, please rate how much<br/> you identify with this text?</strong>",

    assessmentNotFound: "Assessment not found",
  },
  footer: {
    termsOfUse: "TERMS OF USE",
    privacyPolicy: "PRIVACY POLICY",
    company: "A COMPANY",
    copyright: "Copyright",
    reservedRights: "2019 FRST - All rights reserved",
  },

  loadingMicroService: "Loading Micro Service Data",
  continue: "Continue",
  back: "Back",
  configurations: "Configurations",
  backCaps: "BACK",
  exitCaps: "EXIT",

  newAssesment: {
    meuLateral: {
      inicio: "Home",
      desafio: "Challenges and contents",
      assessment: "Asessment",
      galeriaDesafios: "Gallery of challenges",
    },
    textRelatorio: {
      title: "Skills report",
      p1: "The FRST Assessment uses Artificial Intelligence to analyze the socio-emotional profile of each individual. Through it, it is possible to map characteristics and indicate skills that may be useful to understand yourself better.",
      p2: "The purpose of the Assessment is to offer valuable insights so that each individual can get to know himself better and develop his skills more consciously and effectively. Thus, it is possible to create a personalized learning path to reach your goals and follow your progress throughout of the way.",
      p3: "It is important to remember that there are no good or bad socio-emotional characteristics and that the Assessment result is not able to fully represent the plurality of human beings. We are complex, multifaceted beings and it is natural that our characteristics are influenced by the external environment and by culture in which we live.",
    },
    relatorio: {
      dataRelatorio: "Report date: ",
      title: "Detailed Analysis by Competencies",
      descricao:
        "The results are separated into 10 categories of competencies. In each of them, you can identify which characteristics are predominant in your profile.",
    },
    ratingText: "Overall, how much do you identify with this result?",
    downloadText: {
      text: "Click the button below to download your result",
      buttonText: "Result Download",
    },
    experienciaText: {
      title: "How was your experience?",
      descricao:
        "Your opinion is very important to us, so we would like to know how your experience was when carrying out the Assessment and how you felt when viewing the result obtained.",
      tagDescricao: "Please select the subject you wish to speak about below: ",
      listTags: {
        assessmentEmGeral: "Assessment in General",
        processoDeResponderAvaliacao: "Process of answering evaluation",
        flexibilidadeCognitiva: "Cognitive Flexibility",
        solucaoDeProblemasComplexos: "Solution of Complex Problems",
        pensamentoCritico: "Critical Thinking",
        inovacao: "Innovation",
        colaboracao: "Collaboration",
        inteligenciaEmocional: "Emotional Intelligence",
        julgamentoETomadaDeDecisao: "Judgment and Decision-Making",
        orientacaoParaServir: "Orientation to Serve",
        relatorioDeCompetenciasEmGeral: "Report of Competences in general",
        lideranca: "Leadership",
        negociacao: "Negotiation",
      },
      textareaText: {
        p1: "Based on the selected subjects on the side, please, ",
        p2: "write your opinion ",
        p3: "in this field",
        placeholder: "Write your message here",
      },
      buttonEnviar: "Send",
      mensagemSucesso: "Message sent successfully.",
      mensagemError: "Could not send message!",
      mensagemTagsError: "You need to select at least one subject.",
    },
    antigoAssessment: {
      text: "Would you like to view your Assessment result in the previous version?",
      button: "View in previous version",
    },
  },
  menu: { assessmentViewResultPerformed: "View result of " },
};
