import React, { Fragment, useState, useEffect } from 'react';
import Header from './component/header'
import { getMenu } from '../../services/api'

const Page = () => {
    const [userData] = useState(JSON.parse(window.localStorage.getItem('userData')))
    const [menu, setMenu] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function getMenu() {
            getMenuByUser()
        }
        getMenu()
    // eslint-disable-next-line
    }, [])

    const getMenuByUser = async () => {   
        
        const role = userData && 'user'

        let hasMenu
        try{
            hasMenu = JSON.parse(window.sessionStorage.getItem('menu'))
        }
        catch(e) {
            /*eslint-disable */
            hasMenu = [
                {
                    "name": "A FRST FALCONI",
                    "path": "/secure/${role}/start",
                    "component": "MASTER",
                    "role": [],
                    "dropDown": []
                } 
            /*eslint-enable */
            ]
        }

        
        
        // const roleMenu = window.sessionStorage.getItem('role-menu')
        if(hasMenu && hasMenu.length > 0) {
            setMenu(hasMenu)
            setLoading(false)
        } else {
            await getMenu(`/menus/${userData && userData.uuid}`)
            .then(res => {
                if (res && res.data && res.data.length === 0) {
                    
                    /*eslint-disable */
                    window.sessionStorage.setItem('menu', JSON.stringify([{
                        "name": "A FRST FALCONI",
                        "path": "/secure/${role}/start",
                        "component": "MASTER",
                        "role": [],
                        "dropDown": []
                    }])) 
                    /*eslint-enable */
                } 
                else {
                    window.sessionStorage.setItem('menu', JSON.stringify(res.data))
                }
                window.sessionStorage.setItem('role-menu', role)
                window.location.reload()
                
                setMenu(res.data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })            
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    
    return ( loading ? null :
        <Fragment>

            <Header
                role={userData && 'user'}
                menu={menu}
                username={userData && userData.name}
            />

        </Fragment>
    );
}

export default Page;