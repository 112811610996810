import { init as initApm } from '@elastic/apm-rum'

export default class APM {
    static _instance = null

    static getInstance() { 
        if (this._instance == null) {
            this._instance = initApm({

                // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
                serviceName: 'FRONT-ASSESSMENT',
              
                // Use if APM Server requires a secret token
                secretToken: 'eSc3HEuQeYKQcHC1KU',
              
                // Set custom APM Server URL (default: http://localhost:8200/)
                serverUrl: 'https://fa3dca9381ef4142ba0af3ae73817104.apm.us-east-1.aws.cloud.es.io:443/',
              
                // Set the service version (required for source map feature)
                serviceVersion: '0.0.2',
              
                // Set the service environment
                // environment: 'development'
                environment: 'production'
              })
        }
        
        return this._instance;
    }   
    
    static logError(userId, method, error) {
        this.getInstance().captureError(`${userId} | ${method} | ${JSON.stringify(error)}`) 
    }
}