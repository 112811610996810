import axios from "axios"

// const token = window.localStorage.getItem('token')
const authority = JSON.parse(window.localStorage.getItem('authority'))

const config = {
  headers: {
    token: `${process.env.REACT_APP_API_DOMAIN_TOKEN}${process.env.REACT_APP_API_DOMAIN_HASH}`
  },
};

const configMaster = {
  headers: { Authorization: `Bearer ${authority && authority.token}` },
};

export const get = async (url) => {
  return await axios.get(url, config)
    .then(res => res)
    .catch(err => err)
}

export const post = async (url, data) => {
  return await axios.post(url, data, config)
    .then(res => res)
    .catch(err => err)
}

export const put = async (url, data) => {
  return await axios.put(url, data, config)
    .then(res => res)
    .catch(err => err)
}

export const del = async (url) => {
  return await axios.delete(url, config)
    .then(res => res)
    .catch(err => err)
}

export const getMaster = async (url) => {

  return await axios.get(process.env.REACT_APP_API_MASTER + url, configMaster)
    .then(res => res)
    .catch(err => err)

}

export const postMaster = async (url, data) => {

  return await axios.post(process.env.REACT_APP_API_MASTER + url, data, configMaster)
    .then(res => res)
    .catch(err => err)

}

export const putMaster = async (url, data) => {

  return await axios.put(process.env.REACT_APP_API_MASTER + url, data, configMaster)
    .then(res => res)
    .catch(err => err)

}

export const delMaster = async (url) => {

  return await axios.delete(process.env.REACT_APP_API_MASTER + url, configMaster)
    .then(res => res)
    .catch(err => err)

}


export const getMenu = async (url) => {

  return await axios.get(process.env.REACT_APP_API_ADMIN + url, config)
    .then(res => res)
    .catch(err => err)

}
