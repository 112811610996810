import "./index.css";
import React, { useState, Fragment } from "react";
import { Row, Col, Modal } from "antd";
import CompactLoading from "../compactLoading";
import bgVideoAssessment from "../../assets/images/posAssessment.jpg";
// import bgVideoAssessmentPT from "../../assets/images/posAssessmentPT.jpg";
import bgVideoAssessmentPT from "../../assets/images/autoconhecimento.svg";
import arrowRightWhite from "../../assets/images/arrow-right-white.png";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import i18n from "i18next";
import parse from "html-react-parser";
import { useEffect } from "react";
import authService from "../../services/authService";

const widthDimensions = window.innerWidth >= 640 ? 640 : window.innerWidth - 15;

const organizationsBlock = [
  // '66a13520-add3-46dd-a273-7ee99e10893b',
  // '060c3beb-824c-4a83-a0a5-33745086b001'
];

const AssessmentSuccess = (props) => {
  const [user] = useState(authService.getCurrentUser());
  const { t } = useTranslation();
  const DOMAIN_URL_HALL = process.env.REACT_APP_REDIRECT;
  const [modalVideoVisible, setModalVideoVisible] = useState(false);

  const showModalVideo = () => {
    setModalVideoVisible(true);
  };

  useEffect(() => {
    i18n.changeLanguage(user.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const accessResult = () => {
    // if (window.localStorage.getItem("hallAccess") === "true") {
    return `${DOMAIN_URL_HALL}/student/assessment-result/${props.assessmentCode}`;
    // } else {
    //   return `/user/assessment-result/${props.assessmentCode}`;
    // }
  };

  const closeModalVideo = () => {
    setModalVideoVisible(false);
  };

  return (
    <Fragment>
      <div id="con tainer-assessment-sucess">
        <Row justify="center" style={{ marginTop: -5 }}>
          <Col justify="center" sm={12} md={12}>
            {user.language === "en-US" ? (
              <>
                <img className="img-how-works" src={bgVideoAssessment} alt="" onClick={showModalVideo} />
                <img className="img-play-button" src={arrowRightWhite} alt="" onClick={showModalVideo} />

                <div className="text-center">
                  <Modal
                    destroyOnClose={true}
                    visible={modalVideoVisible}
                    footer={null}
                    width={640}
                    zIndex={1061}
                    bodyStyle={{ padding: 0, height: 360 }}
                    onCancel={closeModalVideo}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<iframe src="https://player.vimeo.com/video/475879664?h=1bae4eb234&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="${widthDimensions}" height="360" frameborder="0"  allow="autoplay; fullscreen" allowfullscreen ></iframe>`,
                      }}
                    />
                  </Modal>
                </div>
              </>
            ) : (
              <>
                <img
                  className="img-how-works"
                  src={bgVideoAssessmentPT}
                  alt=""
                  style={{
                    display: "flex",
                    maxWidth: "500px",
                    margin: "0 auto",
                  }}
                // onClick={showModalVideo}
                />
                {/* <img
                  className="img-play-button"
                  src={arrowRightWhite}
                  alt=""
                  // onClick={showModalVideo}
                /> */}

                <div className="text-center">
                  <Modal
                    destroyOnClose={true}
                    visible={modalVideoVisible}
                    footer={null}
                    width={640}
                    zIndex={1061}
                    bodyStyle={{ padding: 0, height: 360 }}
                    onCancel={closeModalVideo}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<iframe src="https://player.vimeo.com/video/484165063?h=bf22666328&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="${widthDimensions}" height="360" frameborder="0"  allow="autoplay; fullscreen" allowfullscreen ></iframe>`,
                      }}
                    />
                  </Modal>
                </div>
              </>
            )}
          </Col>
        </Row>
        <h1 style={{ marginTop: 40, padding: 12, fontSize: "26px" }} className="title text-center">
          {parse(t("success.text"))}{" "}
          {props.assessmentType === 1 ? "mapa de autoconhecimento" : "mapa de autoconhecimento"}
        </h1>
        {props.assessmentTaskStatus !== "success" || props.assessmentStatus === "close" ? (
          <>
            <CompactLoading
              type={2}
              description={
                props.assessmentTaskStatus === "waiting" ? parse(t("success.status1")) : parse(t("success.status2"))
              }
            />
          </>
        ) : (
          <>
            {organizationsBlock.includes(props.organization) && props.assessmentType === 2 ? (
              <div style={{ padding: 12 }}>
                <p className="description-head">Olá ! Agradecemos a sua resposta.</p>
                <p className="description-head">
                  Conforme informado pelo time de DHO Dexco, o resultado do seu
                  {props.assessmentType === 1 ? " mapa de autoconhecimento" : " mapa de autoconhecimento"} FRST, estará
                  disponível em breve.
                </p>
                <p className="description-head">
                  Em caso de dúvidas ou necessidades adicionais, procure por pelo time de DHO pelo e-mail
                  desenvolvimento.organizacional@dex.co.
                </p>
              </div>
            ) : (
              <div style={{ padding: 12, display: "flex", justifyContent: "center" }}>
                {/* <p className="description-head">
                  {parse(t("success.textDownload"))}{" "}
                  {props.assessmentType === 1 ? "mapa de autoconhecimento" : "mapa de autoconhecimento"}.
                </p> */}

                <a
                  className="btn-big btn-orange  text-center question-question-button-finish"
                  href={accessResult()}
                  target="_top"
                  id="buttom-dowload"
                >
                  {parse(t("success.buttomDownload"))}
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </Fragment>
  );
};

export default AssessmentSuccess;
