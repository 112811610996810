export const TRANSLATIONS_PT_PT = {
  runAssessment: {
    /// Titulo
    title: "Pronto(a) para se conhecer?",
    titleDesc: "Siga as instruções abaixo e bom mapa de autoconhecimento.",
    titleDesc_2: "Siga as instruções abaixo e bom mapa de autoconhecimento.",

    /// Lista de como fazer o assessment
    listOption1:
      "Reserve 40 minutos para realizar o <b>mapa de autoconhecimento</b>.",
    listOption1_2:
      "Reserve 40 minutos para realizar o <b>mapa de autoconhecimento</b>.",
    listOption2:
      "Procure um local calmo e garanta que ninguém irá interromper você.",
    listOption3:
      "Você deverá produzir um texto sobre a sua trajetória profissional, explorando seus objetivos, escolhas, aprendizados, realizações e expectativas futuras. Para a produção do texto você pode escolher entre duas opções: <br> <b>Escrever uma redação</b> ou <b>Responder perguntas</b>.",
    listOption3_2:
      "Nosso mapa de autoconhecimento pode ser preenchido de duas maneiras: por meio de uma redação ou respondendo a perguntas dissertativas. Ao final destas instruções, você poderá escolher qual formato prefere utilizar.",
    listOption4:
      "Em ambas as opções, é preciso escrever no mínimo <b>600 palavras</b> para concluir esta etapa, mas recomendamos que você ultrapasse 1200 palavras. Se você optar por responder perguntas dissertativas, não é necessário preencher as 600 palavras em uma única pergunta - você pode responder várias perguntas até atingir esse limite. Além disso, você pode pular perguntas que preferir não responder.",
    listOption5:
      "Lembramos que <b>não existem respostas certas ou erradas</b>. O mapa de autoconhecimento também não avalia contexto, erros gramaticais ou quantas vezes você reinicia a escrita.",
    listOption5_2:
      "Lembramos que <b>não existem respostas certas ou erradas</b>. O mapa de autoconhecimento também não avalia contexto, erros gramaticais ou quantas vezes você reinicia a escrita.",
    listOption6:
      "A ferramenta irá desbloquear o botão <b>Enviar</b> quando você atingir o número de palavras mínimo para concluir o mapa de autoconhecimento. Selecione-o quando estiver pronto.",
    listOption6_2:
      "A ferramenta irá desbloquear o botão <b>Enviar</b> quando você atingir o número de palavras mínimo para concluir o mapa de autoconhecimento. Selecione-o quando estiver pronto.",
    listOption7:
      "Após enviar suas respostas, nosso algoritmo de inteligência artificial entra em ação. Ele irá analisar seu texto e diagnosticar as suas principais oportunidades de desenvolvimento.",
    listOption8:
      "Você poderá visualizar o resultado e também receberá uma cópia deste relatório por email.",
    listOption8_2:
      "Você poderá visualizar o resultado e também receberá uma cópia deste relatório por email.",

    ///
    assessmentExpireAtPart1:
      "Você deve finalizar o seu mapa de autoconhecimento em até: ",
    assessmentExpireAtPart1_2:
      "Você deve finalizar o seu mapa de autoconhecimento em até: ",
    assessmentExpireAtPart2: " dia(s)",

    ///
    assessmentContinue:
      "Você já iniciou seu mapa de autoconhecimento, deseja continuar?",
    assessmentContinue_2:
      "Você já iniciou seu mapa de autoconhecimento, deseja continuar?",
    assessmentChooseOption:
      "Selecione abaixo qual método para envio de sua preferência:",

    /// Botões
    buttomAssessmentText: "Escrever uma redação",
    buttomAssessmentAnswerAgain: "Responder Novamente",
    buttomAssessmentAnwserQuestion: "Responder perguntas",
    buttomResetAssessment: "Não, reiniciar",
    buttomResetAssessment_2: "Não, reiniciar",
    buttomContinueAssessment: "Sim, continuar",
    buttomFinish: "Finalizar",

    /// Modal de Reset do Assessment
    modalResetAssessmentTitle: "Reiniciar mapa de autoconhecimento",
    modalResetAssessmentOk: "Confirmar",
    modalResetAssessmentCancel: "Não",

    modalResetAssessmentTextLanguage:
      "Se você alterar o Idioma, terá que refazer seu mapa de autoconhecimento.",
    modalResetAssessmentText1: "Tem certeza que deseja reiniciar?",
    modalResetAssessmentText2:
      "Ao clicar em <b>Confirmar</b>, todo o conteúdo produzido até o momento será apagado e você poderá reiniciar o mapa de autoconhecimento em outra modalidade.",
    modalResetAssessmentText3:
      "Você confirma que deseja prosseguir e apagar todos os dados?",

    /// Loading
    loading: "Carregando mapa de autoconhecimento",
    loading_2: "Carregando mapa de autoconhecimento",

    /// Permissão
    permissionTitle: "mapa de autoconhecimento",
    permissionTitle_2: "mapa de autoconhecimento",
    permissionText:
      "No momento não existem mapas de autoconhecimento disponíveis para você realizar.",
    permissionText_2:
      "No momento não existem mapas de autoconhecimento disponíveis para você realizar.",
    permissionBack: "Voltar Para Home.",

    /// Alert Message
    alertResetAssessmentSuccessTitle: "Sucesso",
    alertResetAssessmentSuccessText: "mapa de autoconhecimento Reiniciado",

    /// Alert Error
    alertResetAssessmentErrorTitle: "Erro",
    alertResetAssessmentErrorText:
      "Erro ao reiniciar mapa de autoconhecimento.",
    alertResetReassessmentErrorText:
      "Erro ao reiniciar mapa de autoconhecimento.",
  },
  general: {
    /// ProgressBar
    minimumWords1: "Faltam ",
    minimumWords2: "palavras",
    minimumWords3: "para alcançar o mínimo necessário",

    /// Alert Message
    alertCopyPasteTitle: "Atenção",
    alertCopyPasteText: "Não é possível copiar / colar.",

    /// Buttom
    buttomBack: "Voltar",
    buttomSkipQuestion: "ou Pular Pergunta",
    buttomSendText: "Finalizar",

    /// Loading
    loadingStatus: "Carregando Informações",

    /// Alert Success
    alertResetAssessmentSuccessTitle: "Sucesso",
    alertResetAssessmentSuccessText: "mapa de autoconhecimento Reiniciado",
    alertResetReassessmentSuccessText: "mapa de autoconhecimento Reiniciado",
    /// Alert Error
    alertErrorTitle: "Erro",
    alertSuccessTitle: "Successo",
    alertWarningTitle: "Atenção",

    questaoNaoRespondida: "É necessário responder a questão para enviar!",
  },
  text: {
    /// Titulo
    title: "Redação para análise socioemocional",
    title2: "Questionário para análise socioemocional",
    titleMinimumWords: "Número mínimo de palavras não atingidas.",
    titleDesc: "Siga as instruções abaixo e bom mapa de autoconhecimento.",

    ///
    textMinimumWords:
      "Você não alcançou o número mínimo de palavras necessárias. <br>Responda as próximas questões para terminar seu mapa de autoconhecimento. ",

    /// Loading
    loadingStatus: "Carregando Informações",
    loading: "Carregando Redação",
    loading2: "Carregando Perguntas",

    questionText: "Pergunta",
    questionParte: "de",
    questionMinimumWords: "palavra(s) digitada(s)",
    questionMinimumWords2: "de no mínimo de",
    questionMinimumWords3: "por resposta.",

    warningTextMinimumWords:
      "Todas as questões foram respondidas e não atigimos o número mínimo de palavras necessárias.  <br> Para ter um resultado mais assertivo, reinicie o mapa de autoconhecimento e",
    warningTextMinimumWords2: "preencha o mínimo de 600 palavras ",
    warningTextMinimumWords3: "para concluir corretamente.",

    /// Validation &  Input
    InputRequired: "O campo Título é obrigatório.",
    InputTituloLabel: "Título para o texto",
    InputTitulo: "🖉 Título",

    buttomFinish: "Finalizar",

    /// Buttom
    buttomSaving: "Salvando...",
    buttomSaveLater: "ou Salvar para continuar mais tarde",
    buttomSending: "Enviando...",
    buttomSubmitForEvaluation: "Enviar resposta",

    /// Alert Success
    alertSaveTextSuccess: "Redação salva com sucesso",
    alertSaveQuestionSuccess: "Resposta registrada",
    alertSendTextSuccess: "Redação enviada com sucesso",

    /// Alert Warning
    alertTitleWarning:
      "Você precisa informar o título do texto. Não se preocupe, você poderá alterar depois se desejar.",

    /// Alert Error
    alertGetTextError: "Erro ao obter a redação deste usuário",
    alertSaveTextError: "Erro ao salvar a redação deste usuário",
    alertAssessmentError:
      "Erro ao obter o mapa de autoconhecimento deste usuário",
    alertCreateAssessmentError:
      "Erro ao criar/atualizar mapa de autoconhecimento.",
    alertSendEssayError: "Erro ao enviar Essay.",

    /// Modal de alerta de poucas palavras
    alertTitleMinimumWords:
      "Para avançar no seu mapa de autoconhecimento você precisa:",
    alertTextMinimumWords:
      "<li>Escrever no mínimo <b>600 palavras para concluir esta etapa</b>.</li><li>Quanto maior o texto, mais assertiva será a análise do seu resultado.</li><li>As perguntas são limitadas, portanto é fundamental que você escreva uma média de 20 palavras para cada pergunta.</li>",
  },
  success: {
    text: "Você finalizou o ",
    text2: " enviado com sucesso",

    status1: "Enviando dados",
    status2: "Processando resultados",

    error1: "Está demorando muito? Clique aqui gerar novamente.",

    loading: "Carregando Resultado",

    textDownload: "Clique no botão abaixo para ver o resultado do seu ",
    buttomDownload: "Ver meu resultado",

    messageAssessmentDuration:
      "Aguarde, seu mapa de autoconhecimento está sendo processado.<br>O arquivo estará disponível para download em até 5 minutos.",

    messageDexco:
      "Seu mapa de autoconhecimento foi concluído com sucesso. Estamos gerando seu resultado e em breve ele estará disponível para download.<br><br>Em caso de dúvidas, procure pela equipe de Gente – DHO da Dexco.",
  },
  report: {
    errorData: "Erro ao carregar dados, tente novamente mais tarde!",
    downloadText: "Clique no botão abaixo para baixar seu resultado.",
    downloadButton: "Download do Resultado",
    profissionalFuturo: "PROFISSIONAL DO FUTURO",
    assessmentText:
      "O processo do mapa de autoconhecimento é muito simples.<br/> Ele usa Inteligência Artificial para entender seu perfil socioemocional e correlaciona com as oportunidades de desenvolvimento de competências de cada indivíduo.",

    comoFunciona1: "Como",
    comoFunciona2: "FUNCIONA?",

    caractSocioemocionais_title: "Características Socioemocionais",
    caractSocioemocionais_desc:
      "Utilizamos um texto pessoal escrito por você para identicar suas características.",

    intArtificial_title: "Inteligência Artificial",
    intArtificial_desc:
      "O mapa de autoconhecimento inicia o trabalho da computação cognitiva com o objetivo de correlacionar as suas características com as competências do profissional do futuro.",

    competencias_title: "Competências",
    competencias_part1_desc:
      "Com o resultado do mapa de autoconhecimento em mão, você poderá visualizar as competências presentes em seu perfil com maior ou menor intensidade.",
    competencias_part2_desc:
      "Será possível reconhecer traços e tendências comportamentais, desenvolver um plano de ação para fortalecer alguma competência e até utilizá-lo em sessões de mentoria de carreira.",

    trilha_title: "Competências",
    trilha_desc:
      "Após verificar as lacunas de competências, oferecemos uma trilha de aprendizado personalizada para você, composta por módulos associados a sua necessidade de desenvolvimento.",

    evolucao_title: "Evolução",
    evolucao_desc:
      "Ao final do programa você realiza um mapa de autoconhecimento onde mais informações são coletadas e, assim, conseguimos medir sua evolução.",

    relatorio_part1: "Relatório de",
    relatorio_part2: "COMPETÊNCIAS",

    identificacaoScore_desc:
      "<strong>Você se identifica com este resultado?<br/> Escolha ao lado como se reconhece nele.</strong>",
    ratingScore_desc:
      "<strong>No geral, pontue ao lado o quanto<br/> você se identifica com este texto?</strong>",

    assessmentNotFound: "Autoconhecimento não encontrado",
  },
  footer: {
    termsOfUse: "TERMO DE USO",
    privacyPolicy: "POLÍTICA DE PRIVACIDADE",
    company: "UMA EMPRESA",
    copyright: "Copyright",
    reservedRights: "2019 FRST - Todos os direitos reservados",
  },

  loadingMicroService: "Carregando Dados do Micro Serviço",
  continue: "Continuar",
  back: "Voltar",
  configurations: "Configurações",
  backCaps: "VOLTAR",
  exitCaps: "SAIR",

  newAssesment: {
    meuLateral: {
      inicio: "Inicio",
      desafio: "Desafios e conteúdos",
      assessment: "Autoconhecimento",
      galeriaDesafios: "Galeria de desafios",
    },
    textRelatorio: {
      title: "Relatório de competências",
      p1: "O mapa de autoconhecimento utiliza Inteligência Artificial para analisar o perfil socioemocional de cada indivíduo. Por meio dele, é possível mapear características e indicar habilidades que podem ser úteis para compreender-se melhor.",
      p2: "O objetivo do mapa de autoconhecimento é oferecer insights valiosos para que cada indivíduo possa se conhecer melhor e desenvolver suas habilidades de forma mais consciente e eficaz. Assim, é possível criar um percurso de aprendizagem personalizado para atingir seus objetivos e acompanhar seu progresso ao longo do caminho.",
      p3: "É importante lembrar que não há características socioemocionais boas ou ruins e que o resultado do mapa de autoconhecimento não é capaz de representar totalmente a pluralidade do ser humano. Somos seres complexos, multifacetados e é natural que nossas características sejam influenciadas pelo ambiente externo e pela cultura em que estamos inseridos.",
    },
    relatorio: {
      dataRelatorio: "Data do relatório: ",
      title: "Análise Detalhada por Competências",
      descricao:
        "Os resultados estão separados em 10 categorias de competências. Em cada uma delas, você pode identificar quais características são predominantes no seu perfil.",
    },
    success: {
      text: "Você finalizou o ",
      text2: " enviado com sucesso",

      status1: "Enviando dados",
      status2: "Processando resultados",

      error1: "Está demorando muito? Clique aqui gerar novamente.",

      loading: "Carregando Resultado",

      textDownload: "Clique no botão abaixo para ver o resultado do seu ",
      buttomDownload: "Ver meu resultado",

      messageAssessmentDuration:
        "Aguarde, seu mapa de autoconhecimento está sendo processado. <br>O arquivo estará disponível para download em até 5 minutos.",
      messageDexco:
        "Seu mapa de autoconhecimento foi concluído com sucesso. Estamos gerando seu resultado e em breve ele estará disponível para download.<br><br>Em caso de dúvidas, procure pela equipe de Gente – DHO da Dexco.",
    },
    footer: {
      termsOfUse: "TERMO DE USO",
      privacyPolicy: "POLÍTICA DE PRIVACIDADE",
      company: "UMA EMPRESA",
      copyright: "Copyright",
      reservedRights: "2019 FRST - Todos os direitos reservados",
      ratingText: "No geral, o quanto você se identifica com este resultado?",
      downloadText: {
        text: "Clique no botão abaixo para fazer o download do seu resultado",
        buttonText: "Download do resultado",
      },
    },
    experienciaText: {
      title: "Como foi sua experiência?",
      descricao:
        "Sua opinião é muito importante para nós, por isso gostaríamos de saber como foi sua experiência ao realizar o mapa de autoconhecimento e como se sentiu ao visualizar o resultado obtido.",
      tagDescricao:
        "Por favor, selecione abaixo o assunto sobre o qual deseja falar: ",
      listTags: {
        processoDeResponderAvaliacao: "Processo de responder avaliação",
        flexibilidadeCognitiva: "Flexibilidade Cognitiva",
        solucaoDeProblemasComplexos: "Solução de Problemas Complexos",
        pensamentoCritico: "Pensamento Crítico",
        inovacao: "Inovação",
        colaboracao: "Colaboração",
        inteligenciaEmocional: "Inteligência Emocional",
        julgamentoETomadaDeDecisao: "Julgamento e Tomada de Decisão",
        orientacaoParaServir: "Orientação para Servir",
        relatorioDeCompetenciasEmGeral: "Relatório em geral",
        lideranca: "Liderança",
        negociacao: "Negociação",
      },
      textareaText: {
        p1: "Com base nos assuntos selecionados ao lado, por favor, ",
        p2: "escreva sua opinião ",
        p3: "neste campo",
        placeholder: "Escreva sua mensagem aqui",
      },
      buttonEnviar: "Enviar",
      mensagemSucesso: "Mensagem enviada com sucesso.",
      mensagemError: "Não foi possível enviar a mensagem!",
      mensagemTagsError: "Você precisa selecionar pelo menos um assunto.",
    },
    antigoAssessment: {
      text: "Você gostaria de visualizar o resultado do seu mapa de autoconhecimento na versão anterior?",
      button: "Visualizar na versão anterior",
    },
  },
  menu: {
    assessmentViewResultPerformed: "Ver resultado de ",
  },
};
