import React, { useEffect, useRef, useState } from 'react'
import { gsap, Power2, Sine } from 'gsap'

import './Banner.scss'

import imgGlass from '../../assets/img/glass.svg'
import imgAstronaut from '../../assets/img/astronaut.svg'
import imgNave from '../../assets/img/nave.svg'
import imgSombraNave from '../../assets/img/nave-sombra.svg'
import imgMoon from '../../assets/img/lua.svg'

// import logoFrst from '../../assets/img/logo-frst.svg'

/// I18N
import { useTranslation } from 'react-i18next'
import '../../translations/i18n'
import authService from '../../services/authService'
import moment from 'moment'
import { BackArrow } from '../../assets/images/icons'

const urlHome = process.env.REACT_APP_REDIRECT

function Banner({ data, updateScroll, bannerStatic }) {
  const [scrollY, setScrollY] = useState()

  const [user] = useState(authService.getCurrentUser())

  const dateNow = moment().format('DD/MM/YYYY h:mm:ss')

  const { t } = useTranslation()

  let container = useRef()
  let title = useRef()
  let process = useRef()
  let professional = useRef()
  let ship = useRef()
  let shadow = useRef()
  let astronaut = useRef()
  let moon = useRef()

  useEffect(() => {
    setScrollY(updateScroll)
  }, [updateScroll])

  useEffect(() => {
    if (!bannerStatic) {
      gsap
        .timeline()
        .from(container.current, 1, { autoAlpha: 0, ease: Power2.easeOut }, 0)
        .from(
          title.current,
          1.5,
          { autoAlpha: 0, y: 50, ease: Power2.easeOut },
          1,
        )
        .from(
          process.current,
          1.5,
          { autoAlpha: 0, y: 50, ease: Power2.easeOut },
          1.3,
        )
        .from(
          professional.current,
          1.5,
          { autoAlpha: 0, y: 50, ease: Power2.easeOut },
          2,
        )
        .from(
          ship.current,
          8,
          { scale: 0.1, y: '-500%', ease: Power2.easeOut },
          0,
        )
        .from(
          shadow.current,
          8,
          { autoAlpha: 0, scale: 0, ease: Power2.easeOut },
          0,
        )
        .from(
          astronaut.current,
          4,
          {
            scale: 0.8,
            y: 150,
            ease: Power2.easeOut,
            onComplete: () => {
              moon.current.style.opacity = 1
              moon.current.style.marginTop = '0'
            },
          },
          0,
        )

      gsap
        .timeline({ repeat: -1 })
        .to(astronaut.current, 3, {
          y: '-=5',
          rotate: '+=0.5',
          ease: Sine.easeInOut,
        })
        .to(astronaut.current, 2, {
          y: '+=5',
          rotate: '-=0.5',
          ease: Sine.easeInOut,
        })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <section className="banner" ref={container}>
      <div className='backButton' style={{position: 'absolute', top: 0, marginTop: 50, marginLeft: 64, zIndex:999}} onClick={() => window.open(`${urlHome}/home`, '_top')} >
        <BackArrow /> 
        &nbsp;{t('back')}
      </div>
      {/* <img
        src={logoFrst}
        alt="Assessment"
        style={{ marginLeft: 60, marginTop: -30 }}
      /> */}

      <div className="container">
        <h1 style={{ color: '#fff' }} ref={title}>
          Assessment
        </h1>
        <p
          ref={process}
          dangerouslySetInnerHTML={{ __html: t('report.assessmentText') }}
        />
        <div className="image" ref={astronaut}>
          <div className="face">
            <div style={{ position: 'absolute' }}>
              <img src={user.avatar} alt={''} width={124} height={124} />
              <div
                className="glass"
                //style={{backgroundImage: `url(${data.user_picture})`}}
              >
                <img src={imgGlass} alt="Vidro capacete" />
              </div>
            </div>
          </div>
          <img src={imgAstronaut} alt="Astronaut" />
        </div>

        <div className="name" ref={professional}>
          <h2>{data.result.text?.user?.name}</h2>
          <h3>{dateNow}</h3>        
          <p>{t('report.profissionalFuturo')}</p>          
        </div>
        
      </div>

      <img className="ship" src={imgNave} alt="Nave" ref={ship} />
      <img
        className="shadow"
        src={imgSombraNave}
        alt="Sombra Nave"
        ref={shadow}
      />
      <div
        className="bg-ground-rock"
        style={{ transform: `translateX(${scrollY * 0.03}px)` }}
      />
      <div
        className="bg-ground"
        style={{ transform: `translateX(${scrollY * 0.03}px)` }}
      />
      <div
        className="bg-montain-1"
        style={{ transform: `translateX(${scrollY * 0.06}px)` }}
      />
      <div
        className="bg-montain-2"
        style={{ transform: `translateX(${scrollY * 0.12}px)` }}
      />
      <div
        className="moon"
        ref={moon}
        style={{ transform: `rotate(${scrollY * 0.5 - 8}deg)` }}
      >
        <img
          src={imgMoon}
          style={{ transform: `rotate(${-scrollY * 0.5}deg)` }}
          alt="Lua"
        />
      </div>
    </section>
  )
}

export default Banner
