export const TRANSLATIONS_ES_ES = {
  runAssessment: {
    /// Titulo
    title: "¿Listo(a) para conocernos?",
    titleDesc:
      "Siga las instrucciones a continuación y realice su mapa de autoconhecimento.",
    titleDesc_2:
      "Siga las instrucciones a continuación y realice su mapa de autoconhecimento",

    /// Lista de como fazer o assessment
    listOption1:
      "Tómese 40 minutos para realizar el <b>mapa de autoconhecimento</b>.",
    listOption1_2:
      "Tómese 40 minutos para realizar el <b>mapa de autoconhecimento</b>.",
    listOption2:
      "Busque un lugar tranquilo y asegúrese de que nadie le interrumpa.",
    listOption3:
      "Debe escribir un texto sobre su trayectoria profesional, explorando sus objetivos, elecciones, aprendizaje, logros y futuras expectativas. Para la redacción de texto puede elegir entre dos opciones: <br> <b>Escribir un ensayo</b> o <b>Responder preguntas</b>.",
    listOption3_2:
      "Debe producir un texto sobre cómo ha sido su experiencia de desarrollo hasta ahora, los aprendizajes obtenidos y los resultados logrados, y cómo las competencias desarrolladas le han ayudado en los desafíos diarios y cómo se conectan con sus objetivos profesionales futuros. Para la redacción del texto se puede elegir entre dos opciones: <b>Escribir un ensayo</b> o <b>Responder preguntas</b>.",
    listOption4:
      "En ambas opciones, deberá escribir <b>al menos 600 palabras para completar este paso</b>, pero nuestra sugerencia es que supere las 1200 palabras. Si selecciona la opción de <b>Responder preguntas</b>, no necesita completar las 600 palabras en una sola pregunta - puede responder varias preguntas hasta que alcance ese valor. Puede omitir preguntas que no desee responder",
    listOption5:
      "Le recordamos que <b>no hay respuestas correctas o incorrectas</b>. mapa de autoconhecimento tampoco evalúa el contexto, los errores gramaticales o la frecuencia con la que reinicia la escritura.",
    listOption5_2:
      "Le recordamos que <b>no hay respuestas correctas o incorrectas</b>. mapa de autoconhecimento tampoco evalúa el contexto, los errores gramaticales o cuántas veces reinicia la escritura.",
    listOption6:
      "El programa desbloqueará el botón <b>Enviar</b> Envíe sus respuestas cuando alcance el número mínimo de palabras para completar el mapa de autoconhecimento. Selecciónelo cuando haya concluido.",
    listOption6_2:
      "El programa desbloqueará el botón <b>Enviar</b> Envíe sus respuestas cuando alcance el número mínimo de palabras para completar el mapa de autoconhecimento. Selecciónelo cuando haya concluido.",
    listOption7:
      "Después de enviar sus respuestas, nuestro algoritmo de inteligencia artificial entrará en acción. Analizará su texto y diagnosticará sus principales oportunidades de desarrollo",
    listOption8:
      "Podrá ver el resultado individual del mapa de autoconhecimento en la pantalla, así como recibir una copia de este informe por correo electrónico.",
    listOption8_2:
      "Podrá ver el resultado individual del mapa de autoconhecimento en la pantalla, así como recibir una copia de este informe por correo electrónico.",

    ///
    assessmentExpireAtPart1:
      "Debe finalizar su mapa de autoconhecimento hasta: ",
    assessmentExpireAtPart1_2:
      "Debe finalizar su mapa de autoconhecimento hasta: ",
    assessmentExpireAtPart2: " día(s)",

    ///
    assessmentContinue:
      "Ha comenzado su mapa de autoconhecimento, ¿desea continuar?",
    assessmentContinue_2:
      "Ha comenzado su mapa de autoconhecimento, ¿quiere continuar?",
    assessmentChooseOption:
      "Seleccione a continuación qué tipo de redacción de texto prefiere:",

    /// Botões
    buttomAssessmentText: "Escribir un ensayo",
    buttomAssessmentAnswerAgain: "Responder de nuevo",
    buttomAssessmentAnwserQuestion: "Responder preguntas",
    buttomResetAssessment: "No, reiniciar",
    buttomResetAssessment_2: "No, reiniciar",
    buttomContinueAssessment: "Sí, yo quiero",
    buttomFinish: "Finalizar",

    /// Modal de Reset do Assessment
    modalResetAssessmentTitle: "Reiniciar el mapa de autoconhecimento",
    modalResetAssessmentOk: "Confirmar",
    modalResetAssessmentCancel: "No",

    modalResetAssessmentTextLanguage:
      "Si cambia el idioma, tendrá que rehacer su mapa de autoconhecimento.",
    modalResetAssessmentText1: "¿Está seguro de que quiere reiniciar?",
    modalResetAssessmentText2:
      "Al hacer clic en <b>Confirmar</b>, todo el contenido producido hasta ahora se eliminará y podrá reiniciar el  mapa de autoconhecimento en otra modalidad.",
    modalResetAssessmentText3:
      "¿Confirma que desea continuar y eliminar todos los datos?",

    /// Loading
    loading: "Cargando mapa de autoconhecimento",
    loading_2: "Cargando mapa de autoconhecimento",

    /// Permissão
    permissionTitle: "mapa de autoconhecimento",
    permissionTitle_2: "mapa de autoconhecimento",
    permissionText:
      "Por el momento no hay Assessments disponibles para ser realizados.",
    permissionText_2:
      "Por el momento no hay Reassessments disponibles para ser realizados.",
    permissionBack: "Volver a la página principal.",

    /// Alert Message
    alertResetAssessmentSuccessTitle: "Éxito",
    alertResetAssessmentSuccessText: "mapa de autoconhecimento reiniciado",

    /// Alert Error
    alertResetAssessmentErrorTitle: "Error",
    alertResetAssessmentErrorText:
      "Error al reiniciar el mapa de autoconhecimento.",
    alertResetReassessmentErrorText:
      "Error al reiniciar el mapa de autoconhecimento.",
  },
  general: {
    /// ProgressBar
    minimumWords1: "Faltan",
    minimumWords2: "Palabras",
    minimumWords3: "para lograr el mínimo necesario",

    /// Alert Message
    alertCopyPasteTitle: "Atención",
    alertCopyPasteText: "No se puede copiar/pegar.",

    /// Buttom
    buttomBack: "Regresar",
    buttomSkipQuestion: "omitir pregunta",
    buttomSendText: "Enviar respuesta",

    /// Loading
    loadingStatus: "Cargando información",

    /// Alert Success
    alertResetAssessmentSuccessTitle: "Éxito",
    alertResetAssessmentSuccessText: "mapa de autoconhecimento reiniciado",
    alertResetReassessmentSuccessText: "mapa de autoconhecimento reiniciado",

    /// Alert Error
    alertErrorTitle: "Error",
    alertSuccessTitle: "Éxito",
    alertWarningTitle: "Atención",

    questaoNaoRespondida: "¡Es necesario responder a la pregunta para enviar!",
  },
  text: {
    /// Titulo
    title: "Redacción para el análisis socioemocional",
    title2: "Cuestionario para el análisis socioemocional",
    titleMinimumWords: "Número mínimo de palabras no alcanzadas.",
    titleDesc:
      "Siga las instrucciones a continuación y éxito en su  mapa de autoconhecimento.",

    ///
    textMinimumWords:
      "No ha alcanzado el número mínimo de palabras requeridas. <br>Responda las siguientes preguntas para terminar su mapa de autoconhecimento.",

    /// Loading
    loadingStatus: "Cargando información",
    loading: "Cargando redacción",
    loading2: "Cargando preguntas",

    questionText: "Pregunta",
    questionParte: "de",
    questionMinimumWords: "palabra(s) escrita(s)",
    questionMinimumWords2: "de al menos un mínimo de",
    questionMinimumWords3: "por respuesta.",

    warningTextMinimumWords:
      "Todas las preguntas han sido respondidas y no se ha alcanzado el número mínimo de palabras requeridas. <br> Para obtener un resultado más preciso, reinicie el mapa de autoconhecimento y",
    warningTextMinimumWords2: "complete un mínimo de 600 palabras ",
    warningTextMinimumWords3: "para concluir correctamente.",

    /// Validation &  Input
    InputRequired: "El campo Título es obligatorio.",
    InputTituloLabel: "Título del texto",
    InputTitulo: "🖉 Título",

    buttomFinish: "Finalizar",

    /// Buttom
    buttomSaving: "Guardando...",
    buttomSaveLater: "o guardar para continuar más tarde",
    buttomSending: "Enviando...",
    buttomSubmitForEvaluation: "Enviar para evaluación",

    /// Alert Success
    alertSaveTextSuccess: "Redacción guardada con éxito",
    alertSaveQuestionSuccess: "Respuesta registrada",
    alertSendTextSuccess: "Redacción enviada correctamente",

    /// Alert Warning
    alertTitleWarning:
      "Debe ingresar el título del texto. No se preocupe, puedes cambiar más tarde si lo deseas.",

    /// Alert Error
    alertGetTextError: "Error al obtener la redacción de este usuario",
    alertSaveTextError: "Error al guardar la redacción de este usuario",
    alertAssessmentError:
      "Error al obtener el mapa de autoconhecimento de este usuario",
    alertCreateAssessmentError:
      "Error al crear/actualizar el mapa de autoconhecimento.",
    alertSendEssayError: "Error al enviar el ensayo.",

    /// Modal de alerta de poucas palavras
    alertTitleMinimumWords:
      "Para avanzar en su mapa de autoconhecimento necesita:",
    alertTextMinimumWords:
      "<li>Escribe al menos<b>600 palabras para completar este paso</b>.</li><li>Cuanto más grande sea el texto, más preciso será el análisis de su resultado.</li><li>Las preguntas son limitadas, por lo que es fundamental que escriba un promedio de 20 palabras para cada pregunta.</li>",
  },
  success: {
    text: "Ha terminado el,",
    text2: " enviado correctamente",

    status1: "Enviando datos",
    status2: "Procesando resultados",

    error1: "¿Está tardando demasiado? Haga clic aquí para generar de nuevo.",

    loading: "Cargando resultado",

    textDownload: "Haga clic en el botón de abajo para ver el resultado de su",
    buttomDownload: "Ver mi resultado",

    messageAssessmentDuration:
      "Espere, su mapa de autoconhecimento se está procesando.<br>El archivo estará disponible para su descarga en 5 minutos.",
    messageDexco:
      "Su evaluación se ha completado con éxito. Estamos generando su resultado y pronto estará disponible para su descarga.<br><br>Si tiene alguna pregunta, consígala del equipo de personas - DHO de Dexco",
  },
  report: {
    errorData: "Erro ao carregar dados, tente novamente mais tarde!",
    downloadText: "Clique no botão abaixo para baixar seu resultado.",
    downloadButton: "Download do Resultado",
    profissionalFuturo: "PROFISSIONAL DO FUTURO",
    assessmentText:
      "O processo do mapa de autoconhecimento é muito simples.<br/> Ele usa Inteligência Artificial para entender seu perfil socioemocional e correlaciona com as oportunidades de desenvolvimento de competências de cada indivíduo.",

    comoFunciona1: "Como",
    comoFunciona2: "FUNCIONA?",

    caractSocioemocionais_title: "Características Socioemocionais",
    caractSocioemocionais_desc:
      "Utilizamos um texto pessoal escrito por você para identicar suas características.",

    intArtificial_title: "Inteligência Artificial",
    intArtificial_desc:
      "O mapa de autoconhecimento inicia o trabalho da computação cognitiva com o objetivo de correlacionar as suas características com as competências do profissional do futuro.",

    competencias_title: "Competências",
    competencias_part1_desc:
      "Com o resultado do  mapa de autoconhecimento em mão, você poderá visualizar as competências presentes em seu perfil com maior ou menor intensidade.",
    competencias_part2_desc:
      "Será possível reconhecer traços e tendências comportamentais, desenvolver um plano de ação para fortalecer alguma competência e até utilizá-lo em sessões de mentoria de carreira.",

    trilha_title: "Trilhas",
    trilha_desc:
      "Após verificar as lacunas de competências, oferecemos uma trilha de aprendizado personalizada para você, composta por módulos associados a sua necessidade de desenvolvimento.",

    evolucao_title: "Evolução",
    evolucao_desc:
      "Ao final do programa você realiza um mapa de autoconhecimento onde mais informações são coletadas e, assim, conseguimos medir sua evolução.",

    relatorio_part1: "Relatório de",
    relatorio_part2: "COMPETÊNCIAS",

    identificacaoScore_desc:
      "<strong>Você se identifica com este resultado?<br/> Escolha ao lado como se reconhece nele.</strong>",
    ratingScore_desc:
      "<strong>No geral, pontue ao lado o quanto<br/> você se identifica com este texto?</strong>",

    assessmentNotFound: "mapa de autoconhecimento não encontrado",
  },
  footer: {
    termsOfUse: "TERMO DE USO",
    privacyPolicy: "POLÍTICA DE PRIVACIDADE",
    company: "UMA EMPRESA",
    copyright: "Copyright",
    reservedRights: "2019 FRST - Todos os direitos reservados",
  },

  loadingMicroService: "Carregando Dados do Micro Serviço",
  continue: "Continuar",
  back: "Voltar",
  configurations: "Configurações",
  backCaps: "VOLTAR",
  exitCaps: "SAIR",

  newAssesment: {
    meuLateral: {
      inicio: "Inicio",
      desafio: "Desafíos y contenidos",
      assessment: "Assessment",
      galeriaDesafios: "Galería de desafíos",
    },
    textRelatorio: {
      title: "Informe de habilidades",
      p1: "La Evaluación FRST utiliza Inteligencia Artificial para analizar el perfil socioemocional de cada individuo. A través de ella, es posible mapear características e indicar habilidades que pueden ser útiles para comprenderse mejor a sí mismo.",
      p2: "El objetivo de la Evaluación es ofrecer información valiosa para que cada individuo pueda conocerse mejor a sí mismo y desarrollar sus habilidades de manera más consciente y efectiva. Así, es posible crear un camino de aprendizaje personalizado para alcanzar sus metas y seguir sus progreso a lo largo del camino.",
      p3: "Es importante recordar que no existen características socioemocionales buenas o malas y que el resultado de la Evaluación no logra representar plenamente la pluralidad de los seres humanos. Somos seres complejos, multifacéticos y es natural que nuestras características sean influenciado por el entorno externo y por la cultura en la que vivimos.",
    },
    relatorio: {
      dataRelatorio: "Fecha del informe: ",
      title: "Análisis detallado por competencias",
      descricao:
        "Los resultados están separados en 10 categorías de competencias. En cada una de ellas puedes identificar qué características predominan en tu perfil.",
    },
    ratingText: "En general, ¿cuánto te identificas con este resultado?",
    downloadText: {
      text: "Haga clic en el botón de abajo para descargar su resultado",
      buttonText: "Descarga de resultados",
    },
    footer: {
      termsOfUse: "TERMO DE USO",
      privacyPolicy: "POLÍTICA DE PRIVACIDADE",
      company: "UMA EMPRESA",
      copyright: "Copyright",
      reservedRights: "2019 FRST - Todos os direitos reservados",
    },
    experienciaText: {
      title: "¿Cómo fue tu experiencia?",
      descricao:
        "Tu opinión es muy importante para nosotros, por eso nos gustaría saber cómo fue tu experiencia al realizar la Evaluación y cómo te sentiste al ver el resultado obtenido.",
      tagDescricao:
        "Por favor, seleccione el tema sobre el que desea hablar a continuación: ",
      listTags: {
        assessmentEmGeral: "Evaluación en general",
        processoDeResponderAvaliacao: "Proceso de evaluación de respuesta",
        flexibilidadeCognitiva: "Flexibilidad cognitiva",
        solucaoDeProblemasComplexos: "Solución de Problemas Complejos",
        pensamentoCritico: "Pensamiento crítico",
        inovacao: "Innovación",
        colaboracao: "Colaboración",
        inteligenciaEmocional: "Inteligencia Emocional",
        julgamentoETomadaDeDecisao: "Juicio y toma de decisiones",
        orientacaoParaServir: "Orientación para Servir",
        relatorioDeCompetenciasEmGeral: "Informe de Competencias en general",
        lideranca: "Liderazgo",
        negociacao: "Negociación",
      },
      textareaText: {
        p1: "Basado en los temas seleccionados en el lateral, por favor, ",
        p2: "escribe tu opinión ",
        p3: "neste campo",
        placeholder: "Escribe tu mensaje aquí",
      },
      buttonEnviar: "Enviar",
      mensagemSucesso: "Mensaje enviado correctamente.",
      mensagemError: "¡No se pudo enviar el mensaje!",
      mensagemTagsError: "Debes seleccionar al menos un asunto.",
    },
    antigoAssessment: {
      text: "¿Le gustaría ver el resultado de su evaluación en la versión anterior?",
      button: "Ver en versión anterior",
    },
  },
  menu: {
    assessmentViewResultPerformed: "Ver resultado de ",
  },
};
