import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import { TRANSLATIONS_PT_BR } from "./pt-BR/translations";
import { TRANSLATIONS_EN } from "./en-US/translations";
import { TRANSLATIONS_PT_PT } from "./pt-pt/translations";
import { TRANSLATIONS_ES_ES } from "./es-ES/translations";
 
i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   resources: {
     "en-US": {
       translation: TRANSLATIONS_EN
     },
     "pt-BR": {
       translation: TRANSLATIONS_PT_BR
     },
     "pt-PT": {
       translation: TRANSLATIONS_PT_PT
     },
     "es": {
       translation: TRANSLATIONS_ES_ES
     }
   }
 });
 
i18n.changeLanguage("pt-BR");