import React from 'react';
import './index.css';
import { Row, Col, Spin } from 'antd';

class CompactLoading extends React.Component {

  render() {
    return (
        
        <>
            <Row style={{ paddingTop: "2em" }} justify="center" >
                <Col>
                    <Spin />
                </Col>
            </Row>        
            <Row justify={this.props.align? this.props.align : "center" } style={{ padding: "0.5em" }}>
                <Col>
                    <span  style={{marginRight: 10}}>{this.props.description}</span>
                </Col>
            </Row>
        </>
    );
  }
}

export default CompactLoading
