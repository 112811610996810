import React, { useEffect, useRef, useState } from "react";
import { gsap, Power2 } from "gsap";
import LocomotiveScroll from "locomotive-scroll";
import { NavLink } from "react-router-dom";

import Banner from "../../../sections/Banner";
import HowTo from "../../../sections/HowTo";
import HowToNoCapsule from "../../../sections/HowTo/HowToNoCapsule";
import Report from "../../../sections/Report";
import assessmentService from "../../../services/assessmentServiceNew";

import "./Report.scss";

/// I18N
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import ComfirmModalDownloadAssessment from "../../../components/ConfirmModal";

function ReportContent({ data }) {
  const [scrollY, setScrollY] = useState();
  const [open, setOpe] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [typeToast, setTypeToast] = useState("");

  const handleCloseModal = () => {
    setOpe(false);
  };

  const handleCloseToastDownload = () => {
    setIsToastOpen(false);
  };

  const { t } = useTranslation();

  var scroll;

  const scrollRef = useRef();
  let container = useRef();
  let logo = useRef();

  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    // eslint-disable-next-line
    scroll = new LocomotiveScroll({
      el: scrollRef.current,
      // smooth: true,
      // tablet: { smooth: true },
      // smartphone: { smooth: true },
      lerp: isMobile ? 0.1 : 0.05,
    });

    scroll.on("scroll", handleScroll);

    setTimeout(function () {
      scroll.update();
    }, 1000);

    gsap
      .timeline()
      .from(
        logo.current,
        0.5,
        { y: -120, scale: 0.5, ease: Power2.easeInOut },
        0
      )
      .from(container.current, 1, { autoAlpha: 0, ease: Power2.easeInOut }, 0);

    return () => {
      scroll.destroy();
    };
  }, []);

  function handleScroll(e) {
    setScrollY(e.scroll.y);
  }

  const userDataa = JSON.parse(window.localStorage.getItem("userData"));

  const handleClickDownload = async (uuidGroup, link) => {
    try {
      const result = await assessmentService.assessmentPDFDownloadEmail(
        uuidGroup,
        link
      );
      if (result?.status === 200) {
        setOpe(true);
      }
      console.log("result", result);
    } catch (error) {
      console.error("Error generating pdf Assessment:", error);
      setTypeToast("error");
      setMessageToast(
        "Erro ao fazer download de arquivo. Por favor, tente novamente."
      );
      setIsToastOpen(true);
      setTimeout(() => {
        setIsToastOpen(false);
      }, 9000);
    }
  };

  return (
    <div className="assessment-home" data-scroll-container ref={scrollRef}>
      <div className="scroll" data-scroll-section ref={container}>
        <NavLink className="logo-frst" to="/" ref={logo}></NavLink>

        <Banner data={data} updateScroll={scrollY} />

        {isMobile ? <HowToNoCapsule data={data} /> : <HowTo data={data} />}

        <ComfirmModalDownloadAssessment
        emailUser={userDataa.email}
        handleCloseModal={handleCloseModal}
        handleCloseToastDownload={handleCloseToastDownload}
        isToastOpen={isToastOpen}
        messageToast={messageToast}
        open={open}
        typeToast={typeToast}
        />

        <Report data={data} />

        <section className="download">
          <p>{t("report.downloadText")}</p>

          <b
            onClick={() => {
              handleClickDownload(
                userDataa.current_group.selectedGroup,
                data.result?.file
              );
            }}
            style={{
              color: "#fff",
              background: "#ff4d0d",
              padding: 8,
              borderRadius: 5,
              marginTop: 20,
            }}
            target="_blank"
            rel="noreferrer"
          >
            {t("report.downloadButton")}
          </b>
        </section>
      </div>
    </div>
  );
}

export default ReportContent;
