import React, { Fragment, useState } from 'react';
import { Avatar, notification, Row, Col, Modal } from 'antd';
import authService from '../../../services/authService';
// import assessmentService from '../../../services/assessmentService';
import assessmentServiceNew from '../../../services/assessmentServiceNew';

import LogoMenu from '../../../assets/images/logo-menu.png'
import IconMenu from '../../../assets/images/menu.png';

import IconPT from '../../../assets/images/language/pt-br.png';
import IconEN from '../../../assets/images/language/en.png';
import IconPT_PT from '../../../assets/images/language/pt.png';
import IconES from '../../../assets/images/language/es.png';


import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
// import renderHTML from 'react-render-html';
import parse from 'html-react-parser';
import APM from '../../../components/apm';

import { CaretLeftOutlined } from '@ant-design/icons'

const Page = (props) => {
    const [authority] = useState(JSON.parse(window.sessionStorage.getItem('authority')))
    const [menu] = useState(props.menu)
    const [username] = useState(props.username)
    const [openSubmenuArea, setOpenSubmenuArea] = useState(false)
    const [submenuLanguage, setSubmenuLanguage] = useState(false)
    const [submenuIndex, setSubmenuIndex] = useState(null)
    const [openMenuMobile, setOpenMenuMobile] = useState(false)
    const [modalResetAssessmentVisible, setModalResetAssessmentVisible] = useState(false);

    const [languageNew, setLanguageNew] = useState("")


    const [userData] = useState(authService.getCurrentUser());

    const { t } = useTranslation();
    
    const goTo = (item) => {
        if(item.component === 'MASTER') {
            // eslint-disable-next-line
            const path = item.path.replace('${role}', props.role)
            window.location.href = `${process.env.REACT_APP_MASTER_URL}${path}`
        } else if(item.component === 'CALENDARIO') {
            // eslint-disable-next-line
            let newPath
            // eslint-disable-next-line
            newPath = item.path.replace('${role}', props.role)
            // eslint-disable-next-line
            newPath = newPath.replace('${token}', authority.token)
            window.location.href = newPath
        } else if(item.component === 'TURMAS') {
            // eslint-disable-next-line
            let newPath
            // eslint-disable-next-line
            newPath = item.path.replace('${role}', props.role)
            // eslint-disable-next-line
            newPath = newPath.replace('${token}', authority.token)
            window.location.href = newPath
        } else if (item.component === 'none') {
            notification.open({
                message: 'Página em construção',
                description: 'Essa página está em contrução, em breve você poderá acessa-la.'
            });
        }
    }
    const goToSettings = (path) => {
        // console.log(path)
        window.location.href = path
    }
    const logout = () => {
        window.localStorage.clear()
        window.sessionStorage.clear()
        window.localStorage.setItem("hallAccess", "false")
        let myUrl = window.location.href.split('//')[1]
        myUrl = myUrl.split('/')[0]
        if(myUrl === 'localhost:3000') {
            window.location.href = `http://localhost:3000/login`
        }
        if(myUrl === 'devcadastro.frstfalconi.com') {
            window.location.href = `https://devmaster.frstfalconi.com/login`
        }
        if(myUrl === 'assessment.frstfalconi.com') {
            window.location.href = `https://master.frstfalconi.com/login`
        }  
        if(myUrl === 'assessment.frstfalconi.cloud') {
            window.location.href = `https://master.frstfalconi.com/login`
        }                          
        if(myUrl === 'front-assessment.dev.frstfalconi.cloud') {
            window.location.href = `https:front-assessment.dev.frstfalconi.cloud/login`
        }   
        /// Redirect temporario     
        if(myUrl === '159.89.93.157:3000') {
            window.location.href = `http://159.89.93.157:3000/login`
        }
    }

    const openSubmenu = () => {
        setOpenSubmenuArea(true)
    }
    const closeSubmenu = () => {
        setOpenSubmenuArea(false)
    }    
    const closeSubmenuLanguage = () => {
        setSubmenuLanguage(false)
    }
    const openSubmenuLanguage = () => {
        setSubmenuLanguage(true)
    }
    const openSubmenuDinamic = (index) => {
        setSubmenuIndex(index)
    }
    const closeSubmenuDinamic = () => {
        setSubmenuIndex(null)
    }
    const openMenuList = () => {
        setOpenMenuMobile(true)
    }
    const closeSubmenuMobile = () => {
        setOpenMenuMobile(false)
    }    
    const closeModalResetAssessment = () => {
        setModalResetAssessmentVisible(false);
        if (userData.language) {
            setLanguageNew(userData.language)    
        }
        else {
            setLanguageNew("pt-BR")
        }
        
    };
    const changeLanguage = (language) => {
        setOpenMenuMobile(false)           
        setLanguageNew(language)
        setModalResetAssessmentVisible(true);
    }

    const resetAssessment = async () => {
        if (userData) {
            /// Atualizando a linguagem
            assessmentServiceNew.setLanguage(userData.uuid, languageNew, userData?.deimos_token).then(res => {
                //comment-ras
                /// Resetando o assessment
                // let assessmentNumber = JSON.parse(window.localStorage.getItem('assessmentType'))

                // assessmentServiceNew.resetAssessment(userData, assessmentNumber)
                // .then(res => {          
                //     /// Atualizando a linguagem do usuário
                //     let userData = JSON.parse(window.localStorage.getItem('userData'))
                //     userData.language = languageNew
                //     window.localStorage.setItem('userData', JSON.stringify(userData));
                //     window.location.reload()                                
            
                //     closeModalResetAssessment();
            
                // })
                // .catch(error => {
                //     APM.logError(userData?.uuid, "resetAssessment Menu", error)  
            
                //     notification['error']({
                //         message: parse(t("general.alertErrorTitle")),
                //         description: parse(t("general.alertResetReassessmentErrorText")) 
                //     });
                // });
                //comment-ras
            })
        }
      }    
    
    // const { SubMenu } = Menu;
    return (
        <Fragment>
            {userData && userData.language && menu ?
                <div className="menu-area">
                    <Row>
                        {/* MOBILE  */}
                        <Col lg={0} md={0} sm={5} xs={5}>
                            <div onClick={openMenuList} className="list-menu-area">
                                <img alt="menu" src={IconMenu} style={{ width: 25}} />
                            </div>
                        </Col>
                        {!openMenuMobile ? null :
                            <div onMouseLeave={closeSubmenuMobile} className="menu-mobile">
                                <div className="menu-mobile-area" style={{}}>
                                    <div className='link-area-submenu' style={{paddingTop: 20, paddingBottom: 20}}>
                                        <span onClick={closeSubmenuMobile} className="link"><CaretLeftOutlined /> {t('backCaps')}</span>
                                    </div>
                                    {menu.length > 0 && menu.map((m,i) => 
                                        <div key={i} className='link-area-submenu'>
                                            <span onClick={() => goTo(m)} className="link">{m.name}</span>
                                        </div>
                                    )}
                                    <div className='link-area-submenu' style={{paddingTop: 20, paddingBottom: 20}}>
                                        <span onClick={logout} className="link">{t('exitCaps')}</span>
                                    </div>
                                </div>
                            </div>
                        }                    
                                
                        {/* Logo  */}
                        <Col lg={3} md={3} sm={5} xs={5}>
                            <img src={LogoMenu} alt="frst-logo" className="logo-menu" />
                        </Col>
                        {/* WEB  */}
                        <Col lg={14} md={144} sm={0} xs={0} style={{ textAlign: 'center' }}>
                            {menu.length > 0 && menu.map((m,i) => 
                                m.dropDown.length > 0 ?
                                    <>
                                        <div onMouseOver={() => openSubmenuDinamic(i)} className="link-area-menu">
                                            <span className="link">{m.name}</span>
                                            {submenuIndex === i ?
                                                <div onMouseLeave={closeSubmenuDinamic} className="submenu-area-links">
                                                    {m.dropDown.map((d,index) =>
                                                        <div key={index} className='link-area-submenu'>
                                                            <span onClick={() => goTo(m)} key={index} className="link">{d.name}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            :null}
                                        </div>
                                    </>
                                :
                                    <div key={i} onClick={() => goTo(m)} className={m.component === 'CADASTRO' ? 'link-area-active' : 'link-area-menu'}>
                                        <span onClick={() => goTo(m)} className="link">{m.name}</span>
                                    </div>
                            )}
                        </Col>

                        {/* Avatar */}
                        <Col lg={3} md={3} sm={0} xs={0} style={{ textAlign: 'center' }}>
                            <div onMouseLeave={closeSubmenu}>
                                <div onMouseOver={openSubmenu} className="link-area">
                                    <Avatar style={{ marginTop: '-5px', marginRight: '8px' }} />
                                    <span className="link">{username}</span>
                                </div>
                                {!openSubmenuArea ? null :
                                    <div className="submenu-area">
                                        <div className='link-area-submenu'>
                                            <span onClick={() => goToSettings(`${process.env.REACT_APP_MASTER_URL}/account/settings`)} className="link">{t('configurations')}</span>
                                        </div>
                                        <div className='link-area-submenu'>
                                            <span onClick={logout} className="link">Sair</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Col>

                        {/* Language */}
                        
                        {openMenuMobile ? null :
                            <Col lg={4} md={4} sm={0} xs={0} style={{ textAlign: 'center' }}>
                                <div onMouseLeave={closeSubmenuLanguage}>
                                    <div onMouseOver={openSubmenuLanguage} className="link-area-language">
                                        <img alt="language" src={ userData.language === "pt-BR" ? IconPT : userData.language === "pt-PT" ? IconPT_PT : userData.language === "es" ? IconES : IconEN} height={32} style={{ marginTop: '-5px', marginRight: '8px' }} />
                                    </div>
                                    {!submenuLanguage ? null :
                                        <div className="submenu-area-language">
                                            {
                                                userData.language !== "pt-BR" ?
                                                <div className='link-area-submenu-language'>
                                                    <img alt="language" onClick={() => {changeLanguage("pt-BR")}} src={IconPT} height={32} style={{ marginTop: '-5px', marginRight: '8px' }} />
                                                </div>    
                                                :null
                                            }
                                            {
                                                userData.language !== "en-US" ?
                                                <div className='link-area-submenu-language'>
                                                    <img alt="language" onClick={() => {changeLanguage("en-US")}} src={IconEN} height={32} style={{ marginTop: '-5px', marginRight: '8px' }} />
                                                </div> 
                                                :null
                                            }
                                            {
                                                userData.language !== "pt-PT" ?
                                                <div className='link-area-submenu-language'>
                                                    <img alt="language" onClick={() => {changeLanguage("pt-PT")}} src={IconPT_PT} height={32} style={{ marginTop: '-5px', marginRight: '8px' }} />
                                                </div> 
                                                :null
                                            }                                              
                                            {/* {
                                                user.language !== "es" ?
                                                <div className='link-area-submenu-language'>
                                                    <img alt="language" onClick={() => {changeLanguage("es")}} src={IconES} height={32} style={{ marginTop: '-5px', marginRight: '8px' }} />
                                                </div> 
                                                :null
                                            }                                                                          */}
                                            
                                        </div>
                                    }
                                </div>
                            </Col>
                        }
                        
                    </Row>
                </div>
            : null}

            {/* <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" className="menu-global">
                <Menu.Item key="logo-menu">
                    <img src={LogoMenu} alt="frst-logo" className="logo-menu" />
                </Menu.Item>
                {menu.length > 0 && menu.map((m,i) => 
                    m.dropDown.length > 0 ?
                        <SubMenu key={i} title={m.name} className="menu-item">
                            {m.dropDown.map((d,index) =>
                                <Menu.Item className="menu-item submenu" onClick={() => goTo(m)} key={index}>{d.name}</Menu.Item>
                            )}
                        </SubMenu>
                    :
                        <Menu.Item className="menu-item" onClick={() => goTo(m)} key={m.name}>
                            {m.name}
                        </Menu.Item>
                )}

                <SubMenu icon={<Avatar size={30} style={{ marginRight: '10px' }} />} title={username} className="menu-item">
                    <Menu.Item className="menu-item" onClick={() => goToSettings(`${process.env.REACT_APP_MASTER_URL}/account/settings`)}>
                        Configurações
                    </Menu.Item>
                    <Menu.Item className="menu-item" onClick={logout}>
                        Logout
                    </Menu.Item>
                </SubMenu>

            </Menu> */}


        <Modal
            destroyOnClose={true}
            visible={modalResetAssessmentVisible}
            title={parse(t("runAssessment.modalResetAssessmentTitle"))}
            onOk={resetAssessment}
            onCancel={closeModalResetAssessment}
            okText={parse(t("runAssessment.modalResetAssessmentOk"))}
            cancelText={parse(t("runAssessment.modalResetAssessmentCancel"))}
            width={518}
            zIndex={1061}
            bodyStyle={{ padding: 24 }}
            >
            <p>{parse(t("runAssessment.modalResetAssessmentTextLanguage"))}</p>
            <p>{parse(t("runAssessment.modalResetAssessmentText1"))}</p>
            <p>{parse(t("runAssessment.modalResetAssessmentText2"))}</p>
            <p>{parse(t("runAssessment.modalResetAssessmentText3"))}</p>
        </Modal>  

        </Fragment>

    );
}

export default Page;