import styled from "styled-components";

export const Tag = styled.div`
  background: ${(props) => (props.activeTag ? "#6A3F86" : "#450070")};
  border-radius: 40px;
  width: fit-content;
  padding: 7px 16px;
  margin-bottom: 0;
  margin: 0.3rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TagText = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
`;
