import React, { useEffect, useState } from "react";

import "./newReport.css";
import SkillBoard from "../../../../components/skillBoard";

import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";

/// Camada Service
import assessmentService from "../../../../services/assessmentServiceNew";

import {
  Download,
  ExitGreen,
  ExitRed,
  ToastGreen,
  ToastRed,
} from "../../../../assets/images/icons";
import authService from "../../../../services/authService";
import TagAssessment from "../../../../components/tagAssessment";
import ComfirmModalDownloadAssessment from "../../../../components/ConfirmModal";

function NewReport({ data, assessmentSession }) {
  // eslint-disable-next-line
  const [experienceToast, setExperienceToast] = useState(false);

  // eslint-disable-next-line
  const [tagError, setTagError] = useState(false);

  // eslint-disable-next-line
  const [experienceToastSucess, setExperienceToastSucess] = useState(false);

  // eslint-disable-next-line
  const DOMAIN_URL_HALL = process.env.REACT_APP_REDIRECT;

  // eslint-disable-next-line
  const authServiceUser = authService.getCurrentUser();

  // eslint-disable-next-line
  const [userData, setUserData] = useState();

  // eslint-disable-next-line
  const [opninionExperience, setOpninionExperience] = useState("");

  // eslint-disable-next-line
  const [listTags, setListTags] = useState([]);

  /// Controls para os ratings e reactions para cada competencia
  // eslint-disable-next-line
  const [init, setInit] = useState(false);

  const reactionInovacao = data.result.rating.reaction?.inovacao;

  // eslint-disable-next-line
  const [ratingInovacao, setRatingInovacao] = useState(
    data.result.rating.ranking?.inovacao
  );

  const reactionLideranca = data.result.rating.reaction?.lideranca;

  // eslint-disable-next-line
  const [ratingLideranca, setRatingLideranca] = useState(
    data.result.rating.ranking?.lideranca
  );

  const reactionNegociacao = data.result.rating.reaction?.negociacao;

  // eslint-disable-next-line
  const [ratingNegociacao, setRatingNegociacao] = useState(
    data.result.rating.ranking?.negociacao
  );

  const reactionColaboracao = data.result.rating.reaction?.colaboracao;

  // eslint-disable-next-line
  const [ratingColaboracao, setRatingColaboracao] = useState(
    data.result.rating.ranking?.colaboracao
  );

  const reactionPensamentoCritico =
    data.result.rating.reaction?.pensamento_critico;

  // eslint-disable-next-line
  const [ratingPensamentoCritico, setRatingPensamentoCritico] = useState(
    data.result.rating.ranking?.pensamento_critico
  );

  // eslint-disable-next-line
  const reactionInteligenciaEmocional =
    data.result.rating.reaction?.inteligencia_emocional;

  // eslint-disable-next-line
  const [ratingInteligenciaEmocional, setRatingInteligenciaEmocional] =
    useState(data.result.rating.ranking?.inteligencia_emocional);

  // eslint-disable-next-line
  const reactionOrientacaoParaServir =
    data.result.rating.reaction?.orientacao_para_servir;

  // eslint-disable-next-line
  const [ratingOrientacaoParaServir, setRatingOrientacaoParaServir] = useState(
    data.result.rating.ranking?.orientacao_para_servir
  );

  const reactionFlexibilidadeCognitiva =
    data.result.rating.reaction?.flexibilidade_cognitiva;

  // eslint-disable-next-line
  const [ratingFlexibilidadeCognitiva, setRatingFlexibilidadeCognitiva] =
    useState(data.result.rating.ranking?.flexibilidade_cognitiva);

  const reactionJulgamentoTomadaDecisao =
    data.result.rating.reaction?.julgamento_e_tomada_de_decisao;

  // eslint-disable-next-line
  const [ratingJulgamentoTomadaDecisao, setRatingJulgamentoTomadaDecisao] =
    useState(data.result.rating.ranking?.julgamento_e_tomada_de_decisao);

  const reactionSolucaoProblemasComplexos =
    data.result.rating.reaction?.solucao_de_problemas_complexos;

  // eslint-disable-next-line
  const [ratingSolucaoProblemasComplexos, setRatingSolucaoProblemasComplexos] =
    useState(data.result.rating.ranking?.solucao_de_problemas_complexos);

  // eslint-disable-next-line
  const [dataRelatorio, setDataRelatorio] = useState(data.dt_requested);

  /// i18N
  // eslint-disable-next-line
  const { t } = useTranslation();

  /// Sempre que um dos valores for alterado (rating ou reaction) é disparado a
  /// atualização no backend. Como a atualização não é individual, tem que mandar todas sempre
  useEffect(() => {
    /// Esse init é usado para atualizar somente quando a atualização dos states for nos clicks da tela e não da inicialização os valores
    if (init) {
      try {
        /// Montando os dados para atualização no Backend

        /// Informação para o Ratings
        let ratingValues = {
          inovacao: ratingInovacao,
          lideranca: ratingLideranca,
          negociacao: ratingNegociacao,
          colaboracao: ratingColaboracao,
          pensamento_critico: ratingPensamentoCritico,
          inteligencia_emocional: ratingInteligenciaEmocional,
          orientacao_para_servir: ratingOrientacaoParaServir,
          flexibilidade_cognitiva: ratingFlexibilidadeCognitiva,
          julgamento_e_tomada_de_decisao: ratingJulgamentoTomadaDecisao,
          solucao_de_problemas_complexos: ratingSolucaoProblemasComplexos,
        };

        /// Informação para os Reactions
        let reactionsValues = {
          inovacao: reactionInovacao,
          lideranca: reactionLideranca,
          negociacao: reactionNegociacao,
          colaboracao: reactionColaboracao,
          pensamento_critico: reactionPensamentoCritico,
          inteligencia_emocional: reactionInteligenciaEmocional,
          orientacao_para_servir: reactionOrientacaoParaServir,
          flexibilidade_cognitiva: reactionFlexibilidadeCognitiva,
          julgamento_e_tomada_de_decisao: reactionJulgamentoTomadaDecisao,
          solucao_de_problemas_complexos: reactionSolucaoProblemasComplexos,
        };

        assessmentService
          .updateRatingReactions(data.code, ratingValues, reactionsValues)
          .then((result) => { });
      } catch (e) {
        console.log("Erro", e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ratingInovacao,
    reactionInovacao,
    ratingLideranca,
    reactionLideranca,
    ratingNegociacao,
    reactionNegociacao,
    ratingColaboracao,
    reactionColaboracao,
    ratingPensamentoCritico,
    reactionPensamentoCritico,
    ratingInteligenciaEmocional,
    reactionInteligenciaEmocional,
    ratingOrientacaoParaServir,
    reactionOrientacaoParaServir,
    ratingFlexibilidadeCognitiva,
    reactionFlexibilidadeCognitiva,
    ratingJulgamentoTomadaDecisao,
    reactionJulgamentoTomadaDecisao,
    ratingSolucaoProblemasComplexos,
    reactionSolucaoProblemasComplexos,
  ]);

  const listTagsText = [
    {
      name: t(
        "newAssesment.experienciaText.listTags.relatorioDeCompetenciasEmGeral"
      ),
      id: 1,
    },
    {
      name: t("newAssesment.experienciaText.listTags.flexibilidadeCognitiva"),
      id: 2,
    },
    {
      name: t("newAssesment.experienciaText.listTags.lideranca"),
      id: 3,
    },
    {
      name: t(
        "newAssesment.experienciaText.listTags.solucaoDeProblemasComplexos"
      ),
      id: 4,
    },

    {
      name: t("newAssesment.experienciaText.listTags.pensamentoCritico"),
      id: 5,
    },

    {
      name: t("newAssesment.experienciaText.listTags.inovacao"),
      id: 6,
    },
    {
      name: t("newAssesment.experienciaText.listTags.colaboracao"),
      id: 7,
    },
    {
      name: t("newAssesment.experienciaText.listTags.inteligenciaEmocional"),
      id: 8,
    },
    {
      name: t(
        "newAssesment.experienciaText.listTags.julgamentoETomadaDeDecisao"
      ),
      id: 9,
    },
    {
      name: t("newAssesment.experienciaText.listTags.orientacaoParaServir"),
      id: 10,
    },
    {
      name: t("newAssesment.experienciaText.listTags.negociacao"),
      id: 11,
    },
    {
      name: t(
        "newAssesment.experienciaText.listTags.processoDeResponderAvaliacao"
      ),
      id: 12,
    },
  ];

  useEffect(() => {
    const id = setTimeout(() => {
      setExperienceToast(false);
      setExperienceToastSucess(false);
      setTagError(false);
    }, 3000);
    return () => clearTimeout(id);
  }, [experienceToast, experienceToastSucess, tagError]);

  function handleExitToast() {
    setExperienceToast(false);
    setExperienceToastSucess(false);
    setTagError(false);
  }

  function handleEnviarFormExperience() {
    if (listTags.length === 0) {
      setTagError(true);
      setExperienceToast(false);
      setExperienceToastSucess(false);
      return;
    }
    if (opninionExperience === "") {
      setTagError(true);
      setExperienceToast(false);
      setExperienceToastSucess(false);
      return;
    }

    try {
      assessmentService.setAssessmentExperience(
        listTags,
        opninionExperience,
        data.code
      );
      setExperienceToast(true);
      setExperienceToastSucess(true);
      setOpninionExperience("");
      setListTags([]);
    } catch (error) {
      setExperienceToast(true);
      setExperienceToastSucess(false);
    }
  }

  function callbackListTag(item, activeTag) {
    let selectTaglist = listTags;
    if (activeTag) {
      selectTaglist.push(item.name);
    } else {
      let index = selectTaglist.indexOf(item.name);
      listTags.splice(index, 1);
    }
    setListTags(selectTaglist);
  }

  useEffect(() => {
    if (authServiceUser) {
      setUserData(authServiceUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function handleVersionAssesment() {
  //   window.open(
  //     `${DOMAIN_URL_HALL}/student/assessment-result/${data.code}?version=pdf_v2`,
  //     "top_"
  //   );
  // }
  const [scrollY, setScrollY] = useState();
  const [open, setOpe] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [typeToast, setTypeToast] = useState("");

  const handleCloseModal = () => {
    setOpe(false);
  };

  const handleCloseToastDownload = () => {
    setIsToastOpen(false);
  };

  const userDataa = JSON.parse(window.localStorage.getItem('userData'))

  const handleClickDownload = async (uuidGroup, link) =>{
    try {
      const result = await assessmentService.assessmentPDFDownloadEmail(uuidGroup, link)
      if(result?.status === 200){
        setOpe(true);
      }
    } catch (error) {
      console.error("Error generating pdf Assessment:", error);
      setTypeToast("error");
      setMessageToast(
        "Erro ao fazer download de arquivo. Por favor, tente novamente."
      );
      setIsToastOpen(true);
      setTimeout(() => {
        setIsToastOpen(false);
      }, 9000);
    }
      
    }
  

  return (
    <>
    <ComfirmModalDownloadAssessment
    emailUser={userDataa.email}
    handleCloseModal={handleCloseModal}
    handleCloseToastDownload={handleCloseToastDownload}
    isToastOpen={isToastOpen}
    messageToast={messageToast}
    open={open}
    typeToast={typeToast}
    />
      <div className="container-report" id="container-competency-report">
        <div className="header-content">
          <div className="textRelatorio">
            <h1>{t("newAssesment.textRelatorio.title")}</h1>
            <span>
              <p>{t("newAssesment.textRelatorio.p1")}</p>
              <p>{t("newAssesment.textRelatorio.p2")}</p>
              <p>{t("newAssesment.textRelatorio.p3")}</p>
            </span>
          </div>
        </div>
        <div className="content-skills">
          <div className="report-date" id="date">
            {t("newAssesment.relatorio.dataRelatorio")}
            {new Date(dataRelatorio).toLocaleDateString(data.language)}
          </div>

          <div className="skills-description">
            <h3>{t("newAssesment.relatorio.title")}</h3>
            <p style={{ fontSize: 18 }}>
              {t("newAssesment.relatorio.descricao")}
            </p>
          </div>

          {/* Soluçao de Problemas Complexos */}
          <div>
            <SkillBoard
              skillTitle={
                data.result.text.skill_header?.solucao_de_problemas_complexos
                  ?.name
              }
              skillDescription={
                data.result.text.skill_header?.solucao_de_problemas_complexos
                  ?.desc_r1
                  ? data.result.text.skill_header
                    ?.solucao_de_problemas_complexos?.desc_r1
                  : data.result.text.skill_header
                    ?.solucao_de_problemas_complexos?.desc
              }
              rating={ratingSolucaoProblemasComplexos}
              onClickRating={(value) => {
                setRatingSolucaoProblemasComplexos(value);
                setInit(true);
              }}
              listSkills={
                data.result.text.trait_result.solucao_de_problemas_complexos
              }
            />
          </div>

          {/* Pensamento Crítico */}
          <div>
            <SkillBoard
              skillTitle={
                data.result.text.skill_header?.pensamento_critico?.name
              }
              skillDescription={
                data.result.text.skill_header?.pensamento_critico?.desc_r1
                  ? data.result.text.skill_header?.pensamento_critico?.desc_r1
                  : data.result.text.skill_header?.pensamento_critico?.desc
              }
              rating={ratingPensamentoCritico}
              onClickRating={(value) => {
                setRatingPensamentoCritico(value);
                setInit(true);
              }}
              listSkills={data.result.text.trait_result.pensamento_critico}
            />
          </div>

          {/* Inovação */}
          <div>
            <SkillBoard
              skillTitle={data.result.text.skill_header?.inovacao?.name}
              skillDescription={
                data.result.text.skill_header?.inovacao?.desc_r1
                  ? data.result.text.skill_header?.inovacao?.desc_r1
                  : data.result.text.skill_header?.inovacao?.desc
              }
              rating={ratingInovacao}
              onClickRating={(value) => {
                setRatingInovacao(value);
                setInit(true);
              }}
              listSkills={data.result.text.trait_result.inovacao}
            />
          </div>

          {/* lideranca */}
          <div>
            <SkillBoard
              skillTitle={data.result.text.skill_header?.lideranca?.name}
              skillDescription={
                data.result.text.skill_header?.lideranca?.desc_r1
                  ? data.result.text.skill_header?.lideranca?.desc_r1
                  : data.result.text.skill_header?.lideranca?.desc
              }
              rating={ratingLideranca}
              onClickRating={(value) => {
                setRatingLideranca(value);
                setInit(true);
              }}
              listSkills={data.result.text.trait_result.lideranca}
            />
          </div>

          {/* colaboracao */}
          <div>
            <SkillBoard
              skillTitle={data.result.text.skill_header?.colaboracao?.name}
              skillDescription={
                data.result.text.skill_header?.colaboracao?.desc_r1
                  ? data.result.text.skill_header?.colaboracao?.desc_r1
                  : data.result.text.skill_header?.colaboracao?.desc
              }
              rating={ratingColaboracao}
              onClickRating={(value) => {
                setRatingColaboracao(value);
                setInit(true);
              }}
              listSkills={data.result.text.trait_result.colaboracao}
            />
          </div>

          {/* inteligencia_emocional */}
          <div>
            <SkillBoard
              skillTitle={
                data.result.text.skill_header?.inteligencia_emocional?.name
              }
              skillDescription={
                data.result.text.skill_header?.inteligencia_emocional?.desc_r1
                  ? data.result.text.skill_header?.inteligencia_emocional
                    ?.desc_r1
                  : data.result.text.skill_header?.inteligencia_emocional?.desc
              }
              rating={ratingInteligenciaEmocional}
              onClickRating={(value) => {
                setRatingInteligenciaEmocional(value);
                setInit(true);
              }}
              listSkills={data.result.text.trait_result.inteligencia_emocional}
            />
          </div>

          {/* julgamento_e_tomada_de_decisao */}
          <div>
            <SkillBoard
              skillTitle={
                data.result.text.skill_header?.julgamento_e_tomada_de_decisao
                  ?.name
              }
              skillDescription={
                data.result.text.skill_header?.julgamento_e_tomada_de_decisao
                  ?.desc_r1
                  ? data.result.text.skill_header
                    ?.julgamento_e_tomada_de_decisao?.desc_r1
                  : data.result.text.skill_header
                    ?.julgamento_e_tomada_de_decisao?.desc
              }
              rating={ratingJulgamentoTomadaDecisao}
              onClickRating={(value) => {
                setRatingJulgamentoTomadaDecisao(value);
                setInit(true);
              }}
              listSkills={
                data.result.text.trait_result.julgamento_e_tomada_de_decisao
              }
            />
          </div>

          {/* orientacao_para_servir */}
          <div>
            <SkillBoard
              skillTitle={
                data.result.text.skill_header?.orientacao_para_servir?.name
              }
              skillDescription={
                data.result.text.skill_header?.orientacao_para_servir?.desc_r1
                  ? data.result.text.skill_header?.orientacao_para_servir
                    ?.desc_r1
                  : data.result.text.skill_header?.orientacao_para_servir?.desc
              }
              rating={ratingOrientacaoParaServir}
              onClickRating={(value) => {
                setRatingOrientacaoParaServir(value);
                setInit(true);
              }}
              listSkills={data.result.text.trait_result.orientacao_para_servir}
            />
          </div>

          {/* negociacao */}
          <div>
            <SkillBoard
              skillTitle={data.result.text.skill_header?.negociacao?.name}
              skillDescription={
                data.result.text.skill_header?.negociacao?.desc_r1
                  ? data.result.text.skill_header?.negociacao?.desc_r1
                  : data.result.text.skill_header?.negociacao?.desc
              }
              rating={ratingNegociacao}
              onClickRating={(value) => {
                setRatingNegociacao(value);
                setInit(true);
              }}
              listSkills={data.result.text.trait_result.negociacao}
            />
          </div>

          {/* flexibilidade_cognitiva */}
          <div>
            <SkillBoard
              skillTitle={
                data.result.text.skill_header?.flexibilidade_cognitiva?.name
              }
              skillDescription={
                data.result.text.skill_header?.flexibilidade_cognitiva?.desc_r1
                  ? data.result.text.skill_header?.flexibilidade_cognitiva
                    ?.desc_r1
                  : data.result.text.skill_header?.flexibilidade_cognitiva?.desc
              }
              rating={ratingFlexibilidadeCognitiva}
              onClickRating={(value) => {
                setRatingFlexibilidadeCognitiva(value);
                setInit(true);
              }}
              listSkills={data.result.text.trait_result.flexibilidade_cognitiva}
            />
          </div>
        </div>
        <div className="containerButton">
          <p className="containerButtonText">
            {t("newAssesment.downloadText.text")}
          </p>
          <button
            className="buttonDowload"
            onClick={() => handleClickDownload(userDataa.current_group.selectedGroup, data.result?.file)}
            id="button-dowload-result"
          >
            <Download />
            <p className="buttonDowloadText">
              {t("newAssesment.downloadText.buttonText")}
            </p>
          </button>
        </div>
      </div>
      <div className="containerExperience" id="container-your-experience">
        <p className="experienceTitle">
          {t("newAssesment.experienciaText.title")}
        </p>
        <p className="experienceDescription">
          {t("newAssesment.experienciaText.descricao")}
        </p>
        <div className="experienceForm">
          <div className="experienceFormTag">
            <p>{t("newAssesment.experienciaText.tagDescricao")}</p>
            <div className="formTags" id="tags">
              {listTagsText.map((item, index) => {
                return (
                  <TagAssessment
                    key={index}
                    index={index}
                    item={item}
                    onSelectClick={callbackListTag}
                    selectTag={listTags.indexOf(item.name)}
                  />
                );
              })}
            </div>
          </div>
          <div className="experienceFormTextarea" id="form-text-area">
            <p>
              {t("newAssesment.experienciaText.textareaText.p1")}
              <b>{t("newAssesment.experienciaText.textareaText.p2")}</b>{" "}
              {t("newAssesment.experienciaText.textareaText.p3")}
            </p>
            <textarea
              className="formTextarea"
              value={opninionExperience}
              onChange={(e) => setOpninionExperience(e.target.value)}
              placeholder={t(
                "newAssesment.experienciaText.textareaText.placeholder"
              )}
            />
          </div>
        </div>
        <div className="experienceConteinerButton">
          <button
            className="experienceButton"
            onClick={handleEnviarFormExperience}
            id="button-invite"
          >
            {t("newAssesment.experienciaText.buttonEnviar")}
          </button>
        </div>
        {tagError ? (
          <div className="toastContainer">
            <div className="toastRed">
              <ToastRed />
              <p className="toastText">
                {t("newAssesment.experienciaText.mensagemTagsError")}
              </p>
              <button className="toastButton" id="toastButton" onClick={handleExitToast}>
                <ExitRed />
              </button>
            </div>
          </div>
        ) : null}
        {experienceToast ? (
          <div className="toastContainer">
            {experienceToastSucess ? (
              <div className="toastGreen">
                <ToastGreen />
                <p className="toastText">
                  {t("newAssesment.experienciaText.mensagemSucesso")}
                </p>
                <button className="toastButton" id="toastButton" onClick={handleExitToast}>
                  <ExitGreen />
                </button>
              </div>
            ) : (
              <div className="toastRed">
                <ToastRed />
                <p className="toastText">
                  {t("newAssesment.experienciaText.mensagemError")}
                </p>
                <button className="toastButton" id="toastButton" onClick={handleExitToast}>
                  <ExitRed />
                </button>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
}

export default NewReport;
