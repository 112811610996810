import React from "react";
import ReactStars from "react-rating-stars-component";

import './Rating.scss';

function Rating({current, onClickRating}) {

  function ratingChanged(newRating){
    onClickRating(newRating);
  }

  return (
    <div className={"rating"}>
      <ReactStars
        count={5}
        onChange={ratingChanged}
        size={24}
        isHalf={false}
        value={current}
        emptyIcon={
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.6025 1.82628C32.9688 1.08101 34.0312 1.08101 34.3975 1.82628L43.6196 20.5922C43.7648 20.8876 44.0461 21.0927 44.3718 21.1405L65.0036 24.1696C65.8221 24.2897 66.149 25.2952 65.5576 25.8738L50.6246 40.4831C50.39 40.7127 50.283 41.0427 50.3382 41.3663L53.8614 61.9996C54.001 62.8172 53.142 63.4396 52.4086 63.0522L33.9671 53.3109C33.6748 53.1565 33.3252 53.1565 33.0329 53.3109L14.5914 63.0522C13.858 63.4396 12.999 62.8172 13.1386 61.9996L16.6618 41.3663C16.717 41.0427 16.61 40.7127 16.3754 40.4831L1.44236 25.8738C0.85099 25.2952 1.17789 24.2897 1.99642 24.1696L22.6282 21.1405C22.9539 21.0927 23.2352 20.8876 23.3804 20.5922L32.6025 1.82628Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        }
        halfIcon={
          <svg width="64" height="52" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.6025 1.82628C32.9688 1.08101 34.0312 1.08101 34.3975 1.82628L43.6196 20.5922C43.7648 20.8876 44.0461 21.0927 44.3718 21.1405L65.0036 24.1696C65.8221 24.2897 66.149 25.2952 65.5576 25.8738L50.6246 40.4831C50.39 40.7127 50.283 41.0427 50.3382 41.3663L53.8614 61.9996C54.001 62.8172 53.142 63.4396 52.4086 63.0522L33.9671 53.3109C33.6748 53.1565 33.3252 53.1565 33.0329 53.3109L14.5914 63.0522C13.858 63.4396 12.999 62.8172 13.1386 61.9996L16.6618 41.3663C16.717 41.0427 16.61 40.7127 16.3754 40.4831L1.44236 25.8738C0.85099 25.2952 1.17789 24.2897 1.99642 24.1696L22.6282 21.1405C22.9539 21.0927 23.2352 20.8876 23.3804 20.5922L32.6025 1.82628Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M33 1.39893C32.8372 1.492 32.6968 1.63446 32.6025 1.82631L23.3805 20.5922C23.2353 20.8877 22.954 21.0927 22.6282 21.1406L1.99646 24.1696C1.17792 24.2898 0.851022 25.2952 1.44239 25.8738L16.3754 40.4832C16.61 40.7127 16.7171 41.0427 16.6618 41.3663L13.1386 61.9997C12.999 62.8172 13.858 63.4396 14.5914 63.0522L33 53.3283V1.39893Z" fill="#F3D224"/>
            <path d="M32.6025 1.82631L32.0641 1.56168L32.0641 1.56168L32.6025 1.82631ZM33 1.39893H33.6C33.6 1.18502 33.4862 0.987289 33.3011 0.879945C33.1161 0.7726 32.8879 0.771878 32.7022 0.878049L33 1.39893ZM23.3805 20.5922L23.919 20.8568L23.919 20.8568L23.3805 20.5922ZM22.6282 21.1406L22.5411 20.5469L22.5411 20.5469L22.6282 21.1406ZM1.99646 24.1696L2.08361 24.7632L2.08361 24.7632L1.99646 24.1696ZM1.44239 25.8738L1.86198 25.4449L1.86198 25.4449L1.44239 25.8738ZM16.3754 40.4832L15.9558 40.912L15.9558 40.912L16.3754 40.4832ZM16.6618 41.3663L17.2533 41.4673L17.2533 41.4673L16.6618 41.3663ZM13.1386 61.9997L13.73 62.1006L13.73 62.1006L13.1386 61.9997ZM14.5914 63.0522L14.8716 63.5827L14.8716 63.5827L14.5914 63.0522ZM33 53.3283L33.2803 53.8588C33.477 53.7549 33.6 53.5507 33.6 53.3283H33ZM33.141 2.09094C33.1816 2.00848 33.2372 1.95444 33.2978 1.9198L32.7022 0.878049C32.4372 1.02956 32.2121 1.26045 32.0641 1.56168L33.141 2.09094ZM23.919 20.8568L33.141 2.09094L32.0641 1.56168L22.842 20.3276L23.919 20.8568ZM22.7154 21.7342C23.2365 21.6577 23.6866 21.3296 23.919 20.8568L22.842 20.3276C22.7839 20.4458 22.6714 20.5278 22.5411 20.5469L22.7154 21.7342ZM2.08361 24.7632L22.7154 21.7342L22.5411 20.5469L1.9093 23.576L2.08361 24.7632ZM1.86198 25.4449C1.62544 25.2135 1.75619 24.8113 2.08361 24.7632L1.9093 23.576C0.599648 23.7682 0.0766068 25.377 1.0228 26.3027L1.86198 25.4449ZM16.795 40.0543L1.86198 25.4449L1.0228 26.3027L15.9558 40.912L16.795 40.0543ZM17.2533 41.4673C17.3416 40.9496 17.1704 40.4215 16.795 40.0543L15.9558 40.912C16.0497 41.0039 16.0925 41.1359 16.0704 41.2653L17.2533 41.4673ZM13.73 62.1006L17.2533 41.4673L16.0704 41.2653L12.5472 61.8987L13.73 62.1006ZM14.3112 62.5217C14.0178 62.6766 13.6742 62.4277 13.73 62.1006L12.5472 61.8987C12.3238 63.2068 13.6983 64.2025 14.8716 63.5827L14.3112 62.5217ZM32.7198 52.7978L14.3112 62.5217L14.8716 63.5827L33.2803 53.8588L32.7198 52.7978ZM33.6 53.3283V1.39893H32.4V53.3283H33.6Z" fill="#F3D224"/>
          </svg>
        }
        filledIcon={
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.6025 1.82628C32.9688 1.08101 34.0312 1.08101 34.3975 1.82628L43.6196 20.5922C43.7648 20.8876 44.0461 21.0927 44.3718 21.1405L65.0036 24.1696C65.8221 24.2897 66.149 25.2952 65.5576 25.8738L50.6246 40.4831C50.39 40.7127 50.283 41.0427 50.3382 41.3662L53.8614 61.9996C54.001 62.8172 53.142 63.4396 52.4086 63.0522L33.9671 53.3109C33.6748 53.1565 33.3252 53.1565 33.0329 53.3109L14.5914 63.0522C13.858 63.4396 12.999 62.8172 13.1386 61.9996L16.6618 41.3663C16.717 41.0427 16.61 40.7127 16.3754 40.4831L1.44236 25.8738C0.85099 25.2952 1.17789 24.2897 1.99642 24.1696L22.6282 21.1405C22.9539 21.0927 23.2352 20.8876 23.3804 20.5922L32.6025 1.82628Z" fill="#F3D224" stroke="#F3D224" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        }
        activeColor="#ffd700"
      />
    </div>
  );
}

export default Rating;