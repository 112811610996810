import React, { Fragment } from 'react';
import { LoadingOutlined } from '@ant-design/icons'


const Page = () => {

    return (
        <Fragment>
            
            <div style={{ marginTop: '200px', marginBottom: '300px' }}>
                <div style={{ width: '50px', margin: 'auto' }}>
                    <LoadingOutlined style={{ fontSize: '50px' }}/>
                </div>
            </div>

        </Fragment>
    );
}

export default Page;