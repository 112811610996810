import React from "react";
import "./index.css";
import Rating from "../reportResult/Rating";
import PercentageBarResult from "../percentageBarResult";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";

function SkillBoard({
  skillTitle,
  skillDescription,
  rating,
  onClickRating,
  listSkills,
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className="skill-board" id="container-skill">
        <h4> {skillTitle} </h4>
        <div className="skill-rating">
          <div className="skill-rating-description">{skillDescription}</div>
          <div className="rating" id="rating">
            <div className="rating-text">{t("newAssesment.ratingText")}</div>
            <Rating
              current={rating}
              onClickRating={(value) => {
                onClickRating(value);
              }}
            />
          </div>
        </div>
        <div className="skill-bar" id="skill-bar-container">
          {listSkills.map((item, index) => {
            return (
              <div key={index}>
                <PercentageBarResult
                  textInfo1={item.desc_f1}
                  percentage1={item.value_f1}
                  textInfo2={item.desc_f2}
                  percentage2={item.value_f2}
                  id="percetage-bar"
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default SkillBoard;
