import React, { useEffect, useState } from "react";
import "../../translations/i18n";
import * as Styles from "./tagStyles";

const TagAssessment = ({ index, item, onSelectClick, selectTag }) => {
  const [activeTag, setActiveTag] = useState(false);

  useEffect(() => {
    setActiveTag(selectTag >= 0);
  }, [selectTag]);

  function handleActiveTag() {
    onSelectClick(item, !activeTag);
    setActiveTag(!activeTag);
  }

  return (
    <Styles.Tag
      className="itemTag"
      key={index}
      id={index}
      onClick={handleActiveTag}
      activeTag={activeTag}
    >
      <Styles.TagText className="itemTagText">{item.name}</Styles.TagText>
    </Styles.Tag>
  );
};

export default TagAssessment;
