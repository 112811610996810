import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios'
import Loading from '../../../components/loading'

const Page = (props) => {
    const [path] = useState(props.match.params.path)
    const [role] = useState(props.match.params.role)
    const [token] = useState(props.match.params.token)

    useEffect(() => {
        window.localStorage.setItem("hallAccess", "false");
        async function setRedirectByUser() {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                const data = { token }
                await axios.post(`${process.env.REACT_APP_API_MASTER}/admin/user/data-user-new-route`, data, config)
                .then(async res => {
                    if(res.data && res.status === 200) {
                        window.localStorage.setItem('authority', JSON.stringify(res.data.authority))
                        window.localStorage.setItem('authority_roles', JSON.stringify(res.data.authority_roles))
                        await axios.get(`${process.env.REACT_APP_API_MASTER}/user/me`, config)
                        .then(res => {
                            if(res.data && res.status === 200) {
                                window.localStorage.setItem('user', JSON.stringify(res.data))
                                window.location.href = `/${role}/${path}`
                            } else {
                                // window.location.href = '/'
                            }    
                        })
                    } else {
                        // window.location.href = '/'
                    }
                })
                .catch(err => {
                    if(err) {
                        // window.location.href = '/'
                    }
                })
            } catch(err) {
                if(err) {
                    // window.location.href = '/'
                }
            }
        }
        setRedirectByUser()
    // eslint-disable-next-line
    }, [])
    
    return (
        <Fragment>

            <Loading />

        </Fragment>
    );
}

export default Page;